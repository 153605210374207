import React, { useEffect, useState } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { IoCheckmarkDoneSharp, IoClose } from 'react-icons/io5';
import { LuArrowUpRight } from 'react-icons/lu';
import { validateEmail } from "../components/utils/formFunctions";
import { GetRequest, PostRequest } from '../components/utils/request';

const Career = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeJobIndex, setActiveJobIndex] = useState(null);
  const [careerList, setCareerList] = useState([])

  const [error, setError] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    location: false,
    designation: false,
    experience: false,
    msg: "",
    qualification: false,
    resume: false
  });

  const [uploadResume, setUploadResume] = useState('');

  const [formInput, setFormInput] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    location: "",
    designation: "",
    experience: "",
    qualification: ""
  })

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', showModal);

    return () => {

      document.body.classList.remove('overflow-visible');
    };
  }, [showModal]);

  const [newdata, setNewdata] = useState([])
  const toggleModal = (data) => {
    console.log("kuch bhi", data._id)
    // var riya = data._id
    setNewdata(data?._id)
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
    setFormInput({
      fullName: '',
      phoneNumber: '',
      email: '',
      location: '',
      qualification: '',
      experience: ''
    });
    // Reset upload resume state
    setUploadResume('');
  };

  const windowOnClick = (event) => {
    if (event.target.classList.contains('modal')) {
      closeModal();
    }
  };

  const toggleJobDetails = (index) => {
    setActiveJobIndex(activeJobIndex === index ? null : index);
  };

  useEffect(() => {
    GetRequest(`${process.env.REACT_APP_API_URL}/users/getcareer`).then((response) => {
      console.log("response", response.data)
      const activeDepartments = response.data.map(department => ({
        ...department,
        careers: department.careers.filter(career => career.isActive) // Filter active careers
      }));

      setCareerList(activeDepartments);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const firstNameHandler = (e) => {
    const inputValue = e.target.value; // Remove leading and trailing spaces
    const alphaValue = inputValue.replace(/[^A-Za-z\s]/g, '');
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) => match.toUpperCase());

    if (capitalizedValue.length >= 30) {
      setError((prevState) => ({ ...prevState, fullName: "Full Name must be less than or equal to 30 characters" }));
    } else {
      setFormInput((prevState) => ({ ...prevState, fullName: capitalizedValue }));
      setError((prevState) => ({ ...prevState, fullName: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value.trim();
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormInput((prevState) => ({ ...prevState, phoneNumber: numericValue }));
      setError((prevState) => ({ ...prevState, phoneNumber: false }));
    }

  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setError(prevState => ({ ...prevState, uploadResume: "Please select an image file." }));
      setUploadResume('');
      return;
    }

    if (!selectedFile.type.startsWith("application/pdf") && !selectedFile.type.startsWith("application/msword")) {
      setError(prevState => ({ ...prevState, uploadResume: "Please select a valid image file." }));
      setUploadResume('');
      return;
    }
    else {
      setFormInput((prevState) => ({
        ...prevState,
        uploadResume: e.target.value,
      }));
      setError((prevState) => ({
        ...prevState,
        uploadResume: false,
        message: "",
      }));
    }

    setUploadResume(selectedFile);
    e.target.value = null;

  };

  
  const validateForm = () => {
    let newErrors = {};
    // setDisabled(true);
    if (!formInput.fullName) newErrors.fullName = 'FullName is required';
    if (!formInput.phoneNumber) newErrors.phoneNumber = 'PhoneNumber is required';
    if (!formInput.location) newErrors.location = 'Location is required';
    if (!formInput.email) newErrors.email = 'Email is required';
    if (!formInput.qualification) newErrors.qualification = 'Qualification is required'; // Validation for logo
    if (!formInput.experience) newErrors.experience = 'Experience is required'; // Validation for logo
    if (!uploadResume) newErrors.uploadResume = 'Resume is required'; // Validation for logo

    setError(newErrors);
    // setDisabled(false);
    return Object.keys(newErrors).length === 0;
  };

  const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError((prevState) => ({ ...prevState, message: "" }))

    if (!validateForm()) {
      console.log('Validation Failed');
      return;
    } else if (formInput.phoneNumber.length < 10) {
      setError((prevState) => ({
        ...prevState,
        // phoneNumber: true,
        phoneNumber: "Phone number is invalid!",
      }));
      // setDisabled(false);
    } else if (!validRegex.test(formInput.phoneNumber)) {
      setError((prevState) => ({
        ...prevState,
        // phone: true,
        phoneNumber: "Phone number must be valid !",
      }));
      // setDisabled(false);
      // else if (/^(\d)\1*$/.test(longitude)) {
      //     alert("Latitude must be a valid number");
    } else if (!validateEmail(formInput.email)) {
      setError((prevState) => ({
        ...prevState,
        // email: true,
        email: "Email is invalid!",
      }));
      // setDisabled(false);
    } else if (formInput.qualification === '000000') {
      setError((prevState) => ({
        ...prevState,
        qualification: "Qualification cannot be 0."
      }));
      // setDisabled(false);
    } else if (formInput.qualification.charAt(0) === '0') {
      setError((prevState) => ({
        ...prevState,
        qualification: "Qualification cannot start with '0'.",
      }));

      // setDisabled(false);
    } else if (formInput.experience === '000000') {
      setError((prevState) => ({
        ...prevState,
        experience: "Experience cannot be 0."
      }));
      // setDisabled(false);
    } else if (formInput.experience.charAt(0) === '0') {
      setError((prevState) => ({
        ...prevState,
        experience: "Experience cannot start with '0'.",
      }));
      // setDisabled(false);
    }

    else {
      const formDataToSend = new FormData();
      formDataToSend.append("fullName", formInput.fullName);
      formDataToSend.append("phoneNumber", formInput.phoneNumber);
      formDataToSend.append("location", formInput.location);
      formDataToSend.append("email", formInput.email);
      formDataToSend.append("qualification", formInput.qualification);
      formDataToSend.append("designation", newdata);
      formDataToSend.append("experience", formInput.experience);
      formDataToSend.append("resume", uploadResume);

      try {
        const response = await PostRequest(`${process.env.REACT_APP_API_URL}/users/addcareers`, formDataToSend);
        console.log("response===>>>>>>>>>>>>>>", response)

        if (response) {
          setTimeout(() => {
            setError((prevState) => ({ ...prevState, success: false }));
            // setDisabled(false);
            setFormInput({
              fullName: '',
              phoneNumber: '',
              email: '',
              location: '',
              qualification: '',
              experience: ''
            });
            setUploadResume('');
            setShowModal(false);
          }, 3000);

        }
        setError((prevState) => ({ ...prevState, success: true }));
      }
      catch (errors) {
        console.log('Error adding customer:', errors);
        setError(prevState => ({ ...prevState, message: errors.response }));
        // setDisabled(false);
      }
    }

  }

  return (
    <Layout>
      <InnerPageBanner title="Careers" innerPageBanner={ImageIcons.contactBg} />

      <section className='relative'>
        <div className='container '>
          <div className='grid md:grid-cols-2 lg:gap-[50px] gap-[40px] items-center'>
            <div className='w-full md:h-[450px] sm:mh-[350px] min-h-[300px]'>
              <img className='object-cover h-full w-full rounded-[2px]' src={ImageIcons.hiring} alt='job Opening' />
            </div>
            <div className='md:py-[25px]'>
              <h2 className='font-bold'>We are<span className='text-[#2348ef]'> Hiring!</span></h2>
              <p>We are on the hunt for the next generation of innovators with new ideas and visions to make this world a better place for everyone. If you’re one of those who knows how to carve new paths in a crowded territory, our company would love to talk to you.</p>
              <p>So, what are you waiting for? </p>
              <p className='mb-0'> Grab the opportunity to become a part of our organization. We want to hear from your side.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='relative pt-0'>
        <div className='container'>
          <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
            <div className='text-[#2348ef] uppercase mb-[5px] '>Applying</div>
            <h2>Current <span className='text-[#2348ef]'>Job</span>  Openings</h2>
          </div>
          {careerList.map((item, i) => (
            <>
              <h3 className='mb-[15px] mt-[30px] text-[26px]'>{item.departmentName}</h3>

              {item.careers.map((item, i) => (

                <div key={i} className='shadow-[0_0_10px_4px_rgba(0,0,0,0.05)] bg-[#fff] mb-[20px] lg:px-[30px] sm:px-[25px] px-[20px] sm:py-[22px] py-[18px] rounded-[2px]'>

                  <div className='flex md:flex-row flex-col md:items-center items-start justify-between '>
                    <div>
                      <h4 className='mb-[10px] text-[22px]'>{item.title}</h4>

                      <div className='flex gap-[15px] max-md:mb-[20px]'>
                        <div class="border min-w-[130px] max-md:border-0 max-sm:w-full px-[12px] max-md:p-0 py-[8px] rounded-[2px] border-solid border-[#96a8f9]">
                          <div className='text-[16px]'>Experience</div>
                          <h4 className='mb-0 text-[#2348ef] text-[18px]'>{item.experience}</h4>
                        </div>

                        <div class="border min-w-[130px] max-md:border-0 max-sm:w-full px-[12px] max-md:p-0 py-[8px] rounded-[2px] border-solid border-[#96a8f9]">
                          <div>Positions</div>
                          <h4 className='mb-0 text-[#2348ef] md::text-[22px] text-[20px]'>{item.positions}</h4>
                        </div>
                      </div>
                    </div>

                    <div className='inline-flex sm:gap-[15px] gap-[8px] max-md:w-full'>
                      <button onClick={() => toggleJobDetails(item)} className='sm:px-[30px] max-sm:w-full px-[10px] duration-500 text-[16px] py-[15px] font-medium text-[#fff] rounded-[2px] bg-[#123a6d] hover:bg-[#2348ef] ease inline-block'>View Details</button>
                      <button onClick={() => toggleModal(item)} className='sm:px-[30px] max-sm:w-full px-[10px] duration-500 text-[16px] py-[15px] font-medium text-[#fff] bg-[#2348ef] rounded-[2px] hover:bg-[#123a6d] ease inline-block'>Apply Now</button>
                    </div>
                  </div>

                  {activeJobIndex === item &&
                    <div className='mt-[30px] md:p-[30px] p-[20px] bg-[#f5f7ff] rounded-[2px]'>
                      <h5 className='text-[18px]'>Job Title: <span className='text-[17px] font-normal'>{item.title}</span></h5>
                      <h5 className='text-[18px]'>Location: <span className='text-[17px] font-normal'>{item.location}</span></h5>
                      <h5 className='text-[18px]'>Job Type: <span className='text-[17px] font-normal'>{item.jobtype}</span></h5>
                      <h5 className='text-[18px]'>Experience: <span className='text-[17px] font-normal'>{item.experience}</span></h5>
                      <h5 className='text-[18px]'>Number of Positions: <span className='text-[17px] font-normal'>{item.positions}</span></h5>
                      <h5 className='text-[18px] mb-[15px]'>Job Description</h5>
                      <p>{item.jobdescription}</p>
                      <h5 className='text-[18px] mb-[15px]'>Required Skills and Qualifications:</h5>
                      <ul >
                        {item.skills.map((item, i) => (
                          <li key={i} className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#2348ef] absolute left-0 top-[5px]' /> {item}</li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
              ))}
            </>
          ))}
        </div>
      </section>

      {showModal && (
        <div className="modal fixed w-full h-full bg-[rgba(0,0,0,0.8)] z-[99999] overflow-y-auto left-0 top-0" onClick={windowOnClick}>
          <div className="relative overflow-y-hidden bg-[white] max-w-[750px] rounded-lg sm:my-[40px] my-[30px] md:mx-auto mx-[20px]">
            <div className='flex items-center justify-between sm:px-[30px] px-[20px] py-[18px] bg-[#2348ef] text-white'>
              <h4 className='mb-0'>Enter Your Details</h4>
              <span className="cursor-pointer rounded" onClick={closeModal}>
                <IoClose className='text-[25px]' />
              </span>
            </div>

            <div className='sm:px-[30px] px-[20px] sm:py-[30px] py-[20px]'>
              <form className="grid sm:grid-cols-2 grid-cols-1 md:gap-[18px] gap-[15px]" onSubmit={handleSubmit}>
                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="fullName"
                  >
                    Full Name*
                  </lable>
                  <input
                    id="fullName"
                    name="fullName"
                    value={formInput.fullName}
                    onChange={firstNameHandler}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                   {error.fullName && <p className="text-[#ff0000]">{error.fullName}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="number"
                  >
                    Phone No.*
                  </lable>
                  <input
                    id="number"
                    name="phoneNumber"
                    value={formInput.phoneNumber}
                    onChange={handlePhoneInputChange}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                  {error.phoneNumber && <p className="text-[#ff0000]">{error.phoneNumber}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="email"
                  >
                    Email*
                  </lable>
                  <input
                    id="email"
                    
                    name="email"
                    value={formInput.email}
                    onChange={(e) => {
                      setFormInput((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        email: false,
                        message: "",
                      }));
                    }}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                  {error.email && <p className="text-[#ff0000]">{error.email}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="Location"
                  >
                    Location *
                  </lable>
                  <input
                    id="Location"
                    type='text'
                    name="location"
                    value={formInput.location}
                    onChange={(e) => {
                      setFormInput((prevState) => ({
                        ...prevState,
                        location: e.target.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        location: false,
                        message: "",
                      }));
                    }}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                  {error.location && <p className="text-[#ff0000]">{error.location}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="number"
                  >
                    Designation*
                  </lable>
                  <select defaultValue={newdata} onChange={(e) => setNewdata(e.target.value)} id="number" className='w-full h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]' >
                    {careerList?.map((department, Index) => (
                      department?.careers?.map((career, i) => (
                        <option key={i} value={career._id}>
                          {career.title}
                        </option>
                      ))
                    ))}
                  </select>
                  {error.designation && <p className="text-[#ff0000]">{error.designation}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="experience"
                  >
                    Experience
                  </lable>
                  <input
                    id="experience"
                    type='text'
                    name="experience"
                    value={formInput.experience}
                    onChange={(e) => {
                      setFormInput((prevState) => ({
                        ...prevState,
                        experience: e.target.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        experience: false,
                        message: "",
                      }));
                    }}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                  {error.experience && <p className="text-[#ff0000]">{error.experience}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="qualification"
                  >
                    Qualification*
                  </lable>
                  <input
                    id="qualification"
                    type='text'
                    name="qualification"
                    value={formInput.qualification}
                    onChange={(e) => {
                      setFormInput((prevState) => ({
                        ...prevState,
                        qualification: e.target.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        qualification: false,
                        message: "",
                      }));
                    }}
                    className="w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]"
                  />
                  {error.qualification && <p className="text-[#ff0000]">{error.qualification}</p>}
                </div>

                <div>
                  <lable
                    className="mb-[8px] inline-block"
                    htmlFor="upload"
                  >
                    Upload Resume*
                  </lable>
                  <div className="relative flex items-center w-full h-[50px] border border-[#acbbff] bg-[#fdfdff] px-[15px] focus:outline-none focus:border-[#2348ef] focus:bg-[#e9edff]" >
                    <input
                      type="file"
                      className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer z-10"
                      id="uploadLogo"
                      onChange={(e) => handleFileChange(e)}

                    />
                    <label htmlFor="uploadLogo">
                      {uploadResume.name}
                    </label>
                    <div className='absolute flex items-center -top-[1px] right-0 h-[50px] px-[25px] text-[17px] duration-500 text-[#fff] bg-[#2348ef] hover:bg-[#000]'>Upload</div>
                  </div>
                  {error.uploadResume && <p className="text-[#ff0000]">{error.uploadResume}</p>}
                </div>

                <div className='sm:col-span-2 col-span-1 text-center'>
                  <button className="relative overflow-hidden z-10 px-[40px] h-[50px] font-medium text-[#fff] bg-[#123A6D] hover:text-[#fff] th-btn inline-flex justify-center items-center">
                    Apply
                    <div class="icon ml-[10px]"><LuArrowUpRight className='text-[20px] btn_arrow' /></div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

    </Layout>
  )
}

const selectOptions = [
  {
    mediaOptions: [
      "Assistant Editor",
      "Programme Producer",
      "Anchor Producer",
      "Video Editor",
      "Senior Cameraman",
      "VMIX Operator",
      "Anchor Cum Producer",
      "Production Executive",
      "Producer",
      "Graphic Designer",
      "Production Coordinator",
      "Senior Producer",
      "Cameraman",
      "Head- Video editor",
      "Makeup Artist",
      "Head Vedio Editor",
      "Uploader",
      "News Editor"
    ],
    developmentOptions: ["React Developer", "HTML/CSS Developer"],
    digitalMarketingOptions: ["Content Writer", "SEO Exectuive"],
  }
]

const jobData = [
  {
    heading: "Media Team",
    jobDataList: [
      {
        title: "Assistant Editor",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "1-5 Years",
        Positions: "4",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
      },
      {
        title: "Programme Producer",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      },
      {
        title: "Anchor Producer",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      },
      {
        title: "Video Editor",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      },
      {
        title: "Senior Cameraman",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      },
    ]
  },
  {
    heading: "Development",
    jobDataList: [
      {
        title: "React Developer",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "1-5 Years",
        Positions: "4",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
      },
      {
        title: "HTML/CSS Developer",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      }
    ]
  },
  {
    heading: "Digital Marketing",
    jobDataList: [
      {
        title: "Content Writer",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "1-5 Years",
        Positions: "4",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
      },
      {
        title: "SEO Exectuive",
        address: "Phase 7, Industrial Area, Sector 74, Mohali, Punjab 160055",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur",
        experience: "2-4 Years",
        Positions: "5",
        location: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
        jobType: "Full-time",
        jobDescription: "As an FTTH (Fiber to the Home) Engineer, you will be responsible for designing, planning, implementing, and maintaining fiber optic networks to deliver high-speed internet services directly to residential and business customers. Your duties may include but are not limited to:",
        requiredList: ['Bachelors degree in Electrical Engineering, Telecommunications, or related field.', 'Proven experience (2-4 years) in designing, deploying, and maintaining fiber optic networks, preferably in the telecommunications industry.', 'Excellent communication skills and the ability to work collaboratively in a team-oriented environment.'],
        jobDataList: ['idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh', 'idsh9uifdhs diofdsh']
      }
    ]
  }


];

export default Career