import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Heading from '../shared/heading';
import ImageIcons from '../imageComponent/ImageIcons';

const Businesses = () => {
    const [activePaneIndex, setActivePaneIndex] = useState(0);

    const handleClick = (index) => {
        setActivePaneIndex(index);
    };

    const businessesRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#our-businesses' && businessesRef.current) {
            businessesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return (
        <section id="our-businesses" ref={businessesRef}>
            <div className='container'>
                <div className='max-w-[1100px] text-center mx-auto'>
                    <Heading title="Our Businesses" text="At VP Group, we run multiple ventures, providing different services to the users. Our brands include" />
                </div>

                <div className='flex max-lg:flex-col'>
                    {businessesData.map((item, index) => (
                        <div
                            key={index}
                            className={` businesses item w-[65px] max-xl:w-[45px] max-lg:w-full cursor-pointer overflow-hidden relative transition-all duration-[1s] ease-[ease] max-xl:mx-[3px] mx-[5px] max-lg:mx-0 my-0 max-xl:my-[5px] max-lg:h-[60px] ${index === activePaneIndex ? 'active w-[calc(100%_-_684px)] max-xl:w-[calc(100%_-_465px)] max-lg:w-full max-lg:!h-auto ' : ''}`}
                            onClick={() => handleClick(index)}
                        >
                            <div>
                                <h3 className='absolute -rotate-90 max-lg:rotate-0 whitespace-nowrap w-[65px] max-lg:w-full max-xl:w-[45px] max-xl:text-[20px] max-lg:text-[20px] text-white font-semibold m-0 bottom-[30px] max-lg:bottom-[unset] max-lg:top-[15px] max-lg:left-[20px]'>{item.title}</h3>
                                <div className='ml-[65px] max-xl:ml-[45px] max-lg:ml-0 max-lg:mt-[60px] px-[40px] max-lg:px-[20px] py-[50px] max-lg:py-[30px] 2xl:h-[600px] h-[720px] max-lg:h-auto bg-[#F3F4FF] flex flex-col gap-y-[45px] max-2xl:gap-y-[35px] max-lg:gap-y-[30px]'>
                                    {item.items.map((itemData, i) => (
                                        <div key={i} className='flex items-center'>
                                            <div className='2xl:min-w-[170px] xl:min-w-[150px] sm:min-w-[120px] min-w-[80px] 2xl:w-[170px] xl:w-[150px] sm:w-[120px] w-[80px]'>
                                                <img className='max-h-[150px]' src={itemData.businessesLogo} alt={itemData.businessesLogoAtl} />
                                            </div>

                                            <div className='xl:pl-[50px] sm:pl-[40px] pl-[20px]'>
                                                <h5 className='leading-[30px] max-2xl:leading-[26px] max-sm:leading-[24px] max-2xl:text-[18px] max-sm:text-[16px] mb-0'>{itemData.heading}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    )
}
const businessesData = [
    {
        title: "Telecommunication",
        items: [
            {
                heading: "MyInternet offers customers a seamless internet experience, as we ensure providing faster and reliable internet services to our users.",
                businessesLogo: ImageIcons.myInternet,
                businessesLogoAtl: "Internet logo",
            },
            {
                heading: "Through MyWifi services, we provide our customers with both corporate and free reliable and secure public Wifi solutions for the purpose of businesses and public spaces.",
                businessesLogo: ImageIcons.myWifi,
                businessesLogoAtl: "My Wifi logo",
            },
            {
                heading: "VP Broadband provides bulk bandwidth and MPLS all over India, providing high-speed smooth connectivity for businesses nationwide.",
                businessesLogo: ImageIcons.vpBradband,
                businessesLogoAtl: "VP Bradband logo",
            },
        ]
    },
    {
        title: "Broadcasting",
        items: [
            {
                heading: "Our IPTV services provide users with a wide range of content and programming tailored to the unique requirements of the users, allowing them to immerse into the world of entertainment.",
                businessesLogo: ImageIcons.skypro,
                businessesLogoAtl: "Skypro logo",
            }
        ]
    },
    {
        title: "IT Infrastructure",
        items: [
            {
                heading: "The Abacus cloud computing solutions providing company ensures providing advanced cloud solutions customised to your needs, assisting you take your business to new heights.",
                businessesLogo: ImageIcons.abacus,
                businessesLogoAtl: "Abacus logo",
            },
            {
                heading: "Colliers is our partner company in the United States, providing cloud services to the users.",
                businessesLogo: ImageIcons.colliers,
                businessesLogoAtl: "Colliers logo",
            },
            {
                heading: "Velosting is a preeminent domain hosting provider offering our users with innovative, reliable and scalable solutions, serving the varying needs of businesses and individuals",
                businessesLogo: ImageIcons.velosting,
                businessesLogoAtl: "Velosting logo",
            },
        ]
    },
    {
        title: "Real Estate",
        items: [
            {
                heading: "Angan homes is a real estate company, providing people with luxurious 3 BHK independent flats located at prime locations at very affordable prices.",
                businessesLogo: ImageIcons.aangan,
                businessesLogoAtl: "Aangan logo",
            }
        ]
    },
    {
        title: "Surveillance & Turnkey",
        items: [
            {
                heading: "Through this venture, we offer customers CCTV surveillance services and cloud storage facilities, ensuring safety and security.",
                businessesLogo: ImageIcons.myCloudCam,
                businessesLogoAtl: "My Cloud Cam logo",
            }
        ]
    },
    {
        title: "Aviation",
        items: [
            {
                heading: "MyJet services of VP Group provide an ultimately luxurious experience of chartered flights, ensuring impeccable services and support.",
                businessesLogo: ImageIcons.myJet,
                businessesLogoAtl: "My Jet logo",
            },
            {
                heading: "Chartered and prompt air ambulance services are being offered by MyAirAmbulance. In this, we offer on-board ICU facilities, so that quick and comprehensive medical services could be provided during the times of emergency.",
                businessesLogo: ImageIcons.myAir,
                businessesLogoAtl: "My Air logo",
            },
        ]
    },
    {
        title: "Entertainment",
        items: [
            {
                heading: "It is our OTT platform through which we provide our users with 300+ live TV channels, movies, short plays and sports content.",
                businessesLogo: ImageIcons.ashke,
                businessesLogoAtl: "Punjabi",
            }
        ]
    },
    {
        title: "Clean Energy",
        items: [
            {
                heading: "MyEVpoint, which is an electric vehicle charging company, offers users with both fast and slow chargers, promoting the usage of sustainable transportation.",
                businessesLogo: ImageIcons.myEv,
                businessesLogoAtl: "My Evpoint Logo",
            }
        ]
    },
    {
        title: "Immigration",
        items: [
            {
                heading: "It is our Punjabi Web Channel which focuses on offering a wide range of immigration and visa consulting services, providing latest updates and guidelines.",
                businessesLogo: ImageIcons.immigration,
                businessesLogoAtl: "Immigration",
            }
        ]
    },
    {
        title: "News Channel",
        items: [
            {
                heading: "Get information about current happenings, business, sports, entertainment etc with our digital platform, MyPunjabiTV.",
                businessesLogo: ImageIcons.punjabi,
                businessesLogoAtl: "My Punjabi TV Logo",
            },
            {
                heading: "With MyHindiTV, you will get access to a broad range of programming including news, politics, sports and bollywood.",
                businessesLogo: ImageIcons.myHindi,
                businessesLogoAtl: "My Hindi TV Logo",
            }
        ]
    },

];
export default Businesses