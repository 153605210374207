import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuDrawer from "./drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoArrowUpSharp } from "react-icons/io5";
import { windowScroll } from "../utils/windowScroll";
import { MdCall } from "react-icons/md";
// import { MdEmail } from "react-icons/md";
// import { FaLocationDot } from "react-icons/fa6";
import ImageIcons from '../imageComponent/ImageIcons';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { BsSendFill } from "react-icons/bs";


const Layout = ({ children, menuColor }) => {
  const [scrollPosition, setScrollPosition] = useState(false);
  const currentYear = new Date().getFullYear();
  const pathname = useLocation().pathname;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 400) {
      setScrollPosition(true);
    } else {
      setScrollPosition(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isSubLinkActive = (subLinks) => {
    return subLinks.some((item) => pathname === item.handle);
  };

  return (
    <>
      <header className="absolute left-0 top-0 z-50 w-full border-b border-[rgba(255,255,255,0.2)]">
        <div className="container">
          <div className="relative flex justify-end items-center lg:h-auto max-lg:py-[10px]">
            <Link to="/" onClick={windowScroll} className="flex items-center absolute left-0 top-0">
              <img src={ImageIcons.logo} className="w-full lg:max-w-[170px] max-lg:max-w-[100px]" alt='Logo' />
            </Link>

            <nav className='lg:block hidden'>
              <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                {data.navLinks.map((link, i) => (
                  <li key={i} className="relative group lg:w-auto w-full xl:ml-[50px] lg:ml-[40px] ml-[30px]">
                    <Link to={link.handle} className={` ${pathname === link.handle || isSubLinkActive(link.subLinks || []) ? "!text-[#d200fe]" : ""} hover:text-[#d200fe] 2xl:text-[18px] xl:text-[17px] lg:inline-block block w-full lg:py-[40px] py-[10px] ${menuColor ? `text-${menuColor}` : 'text-[#000]'} `} >{link.title}</Link>
                    {link.subLinks && (<div className={` transform origin-[bottom_center] duration-500 scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 content=[''] absolute bottom-0 left-[15px] border-transparent border-[15px] ${menuColor ? `!border-b-${menuColor}` : '!border-b-[#123a6d]'} `}></div>)}
                    {link.subLinks && (
                      <ul className="duration-500 origin-[top_center] overflow-hidden transform scale-y-0 invisible opacity-0 visibil translate-z-100 group-hover:scale-y-100 group-hover:translate-z-0 group-hover:visible group-hover:opacity-100 left-0 rounded-b-[10px] shadow-[0px_10px_15px_0px_rgba(0,0,0,0.2)] grid bg-white text-black md:absolute relative top-[100%] md:w-[280px] w-[100%] z-50">
                        {link.subLinks.map((item, i) => (
                          <li key={i} className="w-fill">
                            <Link to={item.handle} className={` ${pathname === item.handle && "bg-[#123a6d] text-white"} border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#123a6d] hover:text-white px-[20px] py-[12px] `}>{item.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className={` inline-block lg:hidden ${menuColor ? ` text-${menuColor}` : 'text-[#000]'}`}>
              <MenuDrawer navLinks={data.navLinks} />
            </div>
          </div>
        </div>
      </header>
      {children}
      <footer className="bg-[#194a89] relative overflow-hidden" >
        <div class="footer-bg absolute w-full h-full bg-repeat-x animate-[slide_80s_linear_infinite] left-0 top-0" style={{ backgroundImage: `url(${ImageIcons.footerMap})` }}></div>
        <div className="container">
          <img className="absolute opacity-50 md:bottom-[110px] bottom-[300px] -right-[180px] animate-[unlimitedrotate_60s_infinite_alternate]" src={ImageIcons.circlehalfft} alt="" />
          <div className="relative z-10 grid lg:grid-cols-4 sm:grid-cols-2 gap-[40px] md:pt-[70px] max-md:pt-[40px] md:pb-[50px] max-md:pb-[30px]">
            <div>
              <div className="foot-logo md:mb-[25px] max-md:mb-[15px]">
                <Link className="inline-block" to="/" onClick={windowScroll}>
                  <img src={ImageIcons.vplogowhite} className="md:h-[85px] h-[65px]" alt='Logo' />
                </Link>
              </div>
              <p className="text-white text-[16px] mb-0">VP Group, a leading enterprise in innovation and offering a broad assortment of services. We are devoted to offer our users with reliable and high-quality services, enhancing their everyday experience along with fostering technological growth.</p>
            </div>

            {footerLinks.map((link, i) => (
              <div key={i}>
                <h3 className="text-white font-medium	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#fff]" >{link.title}</h3>
                <ul className="grid gap-[10px] sm:gap-[12px]">
                  {link.subLinks.map((item, i) => (
                    <li key={i}>
                      <Link to={item.handle} className={`text-[16px] text-[#fff] inline-flex items-center hover:text-[#fb64e0] ${pathname === item.handle && "!text-[#fb64e0]"}`} onClick={windowScroll} ><IoCheckmarkDoneSharp className="mr-[6px] secondary_text" /> {item.link}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div>
              <h3 className="text-white font-medium	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#fff]">Contact Info</h3>
              <div className="grid gap-[12px] sm:gap-[15px] md:gap-[20px]">
                <div className="flex gap-[10px] items-center">
                  <img src={ImageIcons.emailicon} className="text-[#fff] text-[30px] w-[30px]" alt='Email Address' />
                  <Link to={`mailto:${data.mail}`} className="text-[16px] text-[#fff] hover:text-[#fb64e0]">{data.mail}</Link>
                </div>
                <div className="flex gap-[10px] items-center">
                  <MdCall className="text-[#fff] text-[30px] min-w-[30px]" />
                  {/* <img src={ImageIcons.websiteicon} className="text-[#fff] text-[30px] w-[30px]" alt='Website Link' /> */}
                  <Link to={`tel:${data.mobileNo}`} className="text-[16px] text-[#fff] hover:text-[#fb64e0]">{data.mobileNo}</Link>
                </div>
                <div className="flex gap-[10px] items-start">
                  <img src={ImageIcons.locationicon} className="text-[#fff] text-[30px] w-[30px]" alt='Location Address' />
                  <span className="text-[16px] text-[#fff]">{data.address}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-white font-medium	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#fff]">Newsletter</h3>
              <p className="text-white">Sign up today for hints, tips and the latest product news</p>
              <div className="relative">
                <input type="email" className="h-[50px] bg-white w-full focus:outline-none px-[15px] text-[16px] pr-[60px]" placeholder="Enter Your email" />

                <button type="submit" className="h-[50px] w-[50px] bg-[#2348ef] hover:bg-[#d201fe] duration-500 text-white absolute top-0 right-0 flex justify-center items-center"><BsSendFill /></button>

                <div className="flex flex-wrap mt-[20px]">
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] bg-[#FFFFFF]/[20%] hover:bg-[#d201fe] flex justify-center items-center'> <FaFacebookF /></Link>
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] bg-[#FFFFFF]/[20%] hover:bg-[#d201fe] flex justify-center items-center'> <FaTwitter /></Link>
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] bg-[#FFFFFF]/[20%] hover:bg-[#d201fe] flex justify-center items-center'> <FaLinkedinIn /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-[#fff]/[20%] bg-[#194a89] relative z-10">
          <div className="container">
            <div className="grid md:grid-cols-2 items-center py-[20px]">
              <div className="max-md:mb-[10px]">
                <p className="text-[16px] text-[#fff] mb-0 max-md:text-center">©{currentYear} The VP Group. All Rights Reserved.</p>
              </div>
              <div className="flex flex-wrap md:gap-10 max-md:gap-4 md:justify-end max-md:justify-center">
                <Link to='/privacy-policy' onClick={windowScroll} className={`text-[16px] text-[#fff] hover:text-[#fb64e0] ${pathname === '/privacy-policy' && "!text-[#fb64e0]"}`}>Privacy Policy</Link>
                <Link to='/cancellations-and-refunds' onClick={windowScroll} className={`text-[16px] text-[#fff] hover:text-[#fb64e0] ${pathname === '/cancellations-and-refunds' && "!text-[#fb64e0]"}`}>Refund Policy</Link>
                <Link to='/terms-and-conditions' onClick={windowScroll} className={`text-[16px] text-[#fff] hover:text-[#fb64e0] ${pathname === '/terms-and-conditions' && "!text-[#fb64e0]"}`}>Terms And Conditions</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <button className={`' group flex justify-center items-center 2xl:right-[50px] md:right-[30px] duration-700 right-[15px] bg-[#fff] hover:bg-[#1036de] w-[50px] h-[50px] shadow-sm shadow-[#7e95fb] rounded-[10px] z-50 ' ${scrollPosition ? 'opacity-100 overflow-visible fixed transition-[0.8s] bottom-[55px]' : 'bottom-[102%]'}`}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}>
        <IoArrowUpSharp className="text-[#123A6D] text-[22px] group-hover:text-[#fff]" />
      </button>
    </>
  );
};

const data = {
  mobileNo: "9851198555",
  mail: "info@theabacus.in",
  address: "B-70, Industrial Area, Phase 7, Sahibzada Ajit Singh Nagar, Punjab 160055",
  navLinks: [
    // {
    //   title: "Home",
    //   handle: "/"
    // },
    {
      title: "About Us",
      handle: "/about-us"
    },
    {
      title: "Resources",
      handle: "#",
      subLinks: [
        {
          title: "Events",
          handle: "#"
        },
        {
          title: "Testimonials",
          handle: "#"
        },
        {
          title: "Accreditations",
          handle: "/accreditations"
        }
      ]
    },
    {
      title: "Our Businesses",
      handle: "/#our-businesses"
    },
    {
      title: "Contact Us",
      handle: "/contact-us"
    },
    // {
    //   title: "Our Leadership",
    //   handle: "#"
    // },
  ]
}

const footerLinks = [
  {
    title: "Quick Links",
    subLinks: [
      // {
      //   link: "Home",
      //   handle: "/"
      // },
      {
        link: "Our Products",
        handle: "/products"
      },
      {
        link: "About Us",
        handle: "/about-us"
      },
      {
        link: "Contact Us",
        handle: "/contact-us"
      },
      {
        link: "Blogs",
        handle: "/blogs"
      },
      {
        link: "Sitemap",
        handle: "/sitemap"
      },
    ]
  },
];

export default Layout;
