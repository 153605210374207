import React, { useRef } from "react";
import ImageIcons from "../imageComponent/ImageIcons";
import Slider from "react-slick";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { AiFillStar } from "react-icons/ai";
import Heading from "../shared/heading";

const Testimonial = () => {
    const sliderRef = useRef(null);
    let settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        centerMode: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    };

    return (
        <section className='testimonial bg-[#f3f4ff]'>
            <div className='container'>
                <Heading title="What Our Clients Say" />

                <Slider ref={sliderRef} {...settings} className='mt-[-15px]'>
                    {testimonialData.map((item, i) => (
                        <div>
                            <div key={i} className='item my-[15px] py-[30px] lg:px-[30px] px-[30px] rounded-[10px] group '>
                                <div className="relative z-10 text-center">
                                    <div className='flex mx-auto justify-center items-center overflow-hidden mb-[20px] w-[80px] h-[80px] text-[30px] rounded-full bg-[#fff] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] group-hover:!text-[#000]'>
                                        <img src={item.clinetImages} alt="" />
                                    </div>

                                    <h5 className="font-bold mb-1">{item.title}</h5>
                                    <div className="font-bold mb-5 text-[15px] text-[#123a6d]">{item.designation}</div>
                                    <p className="line-clamp-6">{item.text}</p>

                                    <ul className="flex justify-center gap-1">
                                        <li><AiFillStar className="text-[#d201fe] text-[15px]" /></li>
                                        <li><AiFillStar className="text-[#d201fe] text-[15px]" /></li>
                                        <li><AiFillStar className="text-[#d201fe] text-[15px]" /></li>
                                        <li><AiFillStar className="text-[#d201fe] text-[15px]" /></li>
                                        <li><AiFillStar className="text-[#d201fe] text-[15px]" /></li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    ))}
                </Slider>
                <div className='flex justify-center gap-4'>
                    <button type="button" className='text-[#123a6d] duration-500 hover:text-[#1036de] cursor-pointer text-[35px]' onClick={() => sliderRef.current.slickPrev()}>
                        <HiOutlineArrowLongLeft />
                    </button>
                    <button type="button" className='text-[#123a6d] duration-500 hover:text-[#1036de] cursor-pointer text-[35px]' onClick={() => sliderRef.current.slickNext()}>
                        <HiOutlineArrowLongRight />
                    </button>
                </div>
            </div>
        </section>
    )
}

const testimonialData = [
    {
        title: "Manohar Walia",
        designation: "Senior HR Manager",
        text: "I was searching for an independent accommodation to live in and then came to know about Angan homes. The team helped me find my dream house with availability of all the facilities. I must say, I am really  enjoying living at the perfect location provided by them.",
        clinetImages: ImageIcons.client1,
    },
    {
        title: "Swati Oberoi",
        designation: "Sales Manager",
        text: "Recently switched to their IPTV services and I am enjoying entertainment like never before. The feature which I like the most is Personal recommendations through which I always have something new on my feed to watch.",
        clinetImages: ImageIcons.client2,
    },
    {
        title: "Naved Azam",
        designation: "Businessman",
        text: "Purchasing an EV was never easy for me because of the anxiety of charging. However when I came to know about MyEVpoint and experienced their services, it felt like I had made the best decision to purchase an EV. MyEVpoint app proves to be very beneficial for long journeys, as it not only provides location of their own charging stations but also about the availability of others.",
        clinetImages: ImageIcons.client3,
    },
    {
        title: "Anurima Das",
        designation: "Marketing Executive",
        text: "I'm thrilled with my recent switch to IPTV services! The personal recommendations feature stands out the most, ensuring there's always something new and exciting in my feed.",
        clinetImages: ImageIcons.client4,
    },
]
export default Testimonial