import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import Heading from '../shared/heading';
const Team = () => {
  return (
    <section className='relative overflow-hidden bg-[#f3f4ff]'>
      <div className='container '>
        <Heading title="Our Core Team" />

        <div className='grid justify-center lg:grid-cols-3 md:grid-cols-2 gap-x-[50px] max-xl:gap-x-[20px] gap-y-[50px]'>
          <div className='group relative'>
            <div className='xl:h-[510px] lg:h-[450px] md:h-[500px] rounded-[10px] overflow-hidden relative after:duration-500 after:absolute after:content-[""] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(212_0_254_/_100%)_0%,rgb(16_54_222_/_60%)_100%)] group-hover:after:!h-[100%]'>
              <img className='w-full h-full object-cover' src={ImageIcons.ranjitSinghSandhu} alt='' />
              <div className='absolute z-10 bottom-0 group-hover:bottom-[50px] left-0 w-full text-center duration-1000 invisible group-hover:visible group-hover:overflow-visible  h-0 group-hover:h-auto group-hover:mt-[10px]'>
                <p className="mb-0 leading-[18px] group-hover:leading-[24px] duration-1000 p-[15px] text-[#fff]">Ranjit Singh Sandhu has decades of experience, which has been instrumental in assisting the group achieve its goals. Under his leadership, VP Group has grown profoundly into a multifaceted conglomerate, driving breakthroughs across various sectors. His prudent vision and devotion towards innovation assists driving the company forward.</p>
              </div>
            </div>
            <div className='w-[90%] relative z-20 -mt-[40px] mx-auto shadow-[0px_5px_15px_0px_rgba(106,121,255,0.37)] bg-[#fff] text-center p-[20px] rounded-[10px]'>
              <h3 className='mb-[10px] max-2xl:text-[25px] max-xl:text-[20px]'>Ranjeet Singh Sandhu</h3>
              <div>President Emeritus</div>
            </div>
          </div>
          <div className='group relative'>
            <div className='xl:h-[510px] lg:h-[450px] md:h-[500px] rounded-[10px] overflow-hidden relative after:duration-500 after:absolute after:content-[""] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(212_0_254_/_100%)_0%,rgb(16_54_222_/_60%)_100%)] group-hover:after:!h-[100%]'>
              <img className='w-full h-full object-cover' src={ImageIcons.navjotSinghSandhu} alt='' />
              <div className='absolute z-10 bottom-0 group-hover:bottom-[50px] left-0 w-full text-center duration-1000 invisible group-hover:visible group-hover:overflow-visible  h-0 group-hover:h-auto group-hover:mt-[10px]'>
                <p className="mb-0 leading-[18px] group-hover:leading-[24px] duration-1000 p-[15px] text-[#fff]">Navjot Singh Sandhu has played a key role in directing the company’s operations and strategies. His sharp vision has been crucial in enhancing the group’s reach and  impact. His commitment towards customer satisfaction and excellence encourages us to deliver top-tier services to the users.</p>
              </div>
            </div>

            <div className='w-[90%] relative z-20 -mt-[40px] mx-auto shadow-[0px_5px_15px_0px_rgba(106,121,255,0.37)] bg-[#fff] text-center p-[20px] rounded-[10px]'>
              <h3 className='mb-[10px] max-2xl:text-[25px] max-xl:text-[20px]'>Navjot Singh Sandhu</h3>
              <div>Director / CEO</div>
            </div>
          </div>
          <div className='group relative'>
            <div className='xl:h-[510px] lg:h-[450px] md:h-[500px] rounded-[10px] overflow-hidden relative after:duration-500 after:absolute after:content-[""] after:left-0 after:bottom-0 after:w-full after:h-0 after:bg-[linear-gradient(0deg,rgb(212_0_254_/_100%)_0%,rgb(16_54_222_/_60%)_100%)] group-hover:after:!h-[100%]'>
              <img className='w-full h-full object-cover' src={ImageIcons.drPawanpreetDhaliwal} alt='' />
              <div className='absolute z-10 bottom-0 group-hover:bottom-[50px] left-0 w-full text-center duration-1000 invisible group-hover:visible group-hover:overflow-visible  h-0 group-hover:h-auto group-hover:mt-[10px]'>
                <p className="mb-0 leading-[18px] group-hover:leading-[24px] duration-1000 p-[15px] text-[#fff]">Dr. Pawanpreet Dhaliwal has been the driving force behind all the technological innovations of VP Group. His strong background in technology, has led to the development of cutting-edge technologies, which has always helped VP Group stay abreast of others.  His devotion to research, development and sustainability make VP Group a leader in technological advancements.</p>
              </div>
            </div>
 
            <div className='w-[90%] relative z-20 -mt-[40px] mx-auto shadow-[0px_5px_15px_0px_rgba(106,121,255,0.37)] bg-[#fff] text-center p-[20px] rounded-[10px]'>
              <h3 className='mb-[10px] max-2xl:text-[25px] max-xl:text-[20px]'>Dr. Pawanpreet Dhaliwal</h3>
              <div>Director / CTO</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team