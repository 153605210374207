import React from 'react'
import Heading from '../shared/heading';
import ImageIcons from '../imageComponent/ImageIcons';

const CoreValues = () => {
    return (
        <>
            <section className='core-values-section relative bg-[#f3f4ff] bg-auto bg-no-repeat bg-right-top' style={{ backgroundImage: `url(${ImageIcons.corebgvalue})` }}>
                <div className='container m-auto'>
                    <div className='max-w-[1100px] text-center mx-auto'>
                        <Heading title="Core Values" text="At VP Group, every aspect of our business is driven by our core values like shaping our interactions, decisions, and strategies. The foundation of devotion to best services and sustainable future is being formed by these principles." />
                    </div>

                    <div className="relative wrap overflow-hidden pt-2 max-md:pt-0 h-full">
                        <div className="border-gradit-clr absolute h-full md:left-[50%] max-md:left-[27px] md:translate-x-[-50%] bg-no-repeat bg-cover bg-center w-[6px]" style={{ backgroundImage: `url(${ImageIcons.borderlineimg})` }}></div>
                        <div className="flex md:justify-between items-center w-full left-timeline md:flex-row-reverse max-md:mb-[15px] group">
                            <div className="max-md:hidden order-1 w-5/12"></div>
                            <div className="z-20 flex items-center order-1 rounded-full max-md:min-w-[60px] duration-1000 group-hover:scale-[1.1] max-md:group-hover:scale-[1]">
                                <img className='w-[60px] h-[60px]' src={ImageIcons.coreicon1} alt='' />
                            </div>
                            <div className="relative md:order-1 max-md:order-2 bg-[#1036DE] md:w-5/12 max-md:w-full max-md:ms-[20px] md:px-6 max-md:px-3 md:py-[35px] max-md:py-[20px] before:content-[''] before:absolute before:w-[0px] before:h-[0px] md:before:right-[-18px] max-md:before:left-[-18px] before:top-[50%] before:translate-y-[-50%] before:border-t-[15px] before:border-t-[transparent] before:border-solid before:border-b-[15px] before:border-b-[transparent] md:before:border-l-[20px] md:before:border-l-[#1036DE] max-md:before:border-r-[20px] max-md:before:border-r-[#1036DE]">
                                <h3 className="text-white text-[23px] leading-[32px] font-bold mb-3">Innovation and Adaptability</h3>
                                <p className="text-white text-[16px] leading-[24px] font-normal tracking-wide mb-0">At VP Group, we are devoted towards new ideas and innovation, so that we can adapt to the changes happening in the industry and the needs of the customers in a quicker and easier way.</p>
                            </div>
                        </div>
                        <div className="md:mt-[-50px] flex md:justify-between items-center w-full right-timeline max-md:mb-[15px] group">
                            <div className="max-md:hidden order-1 w-5/12"></div>
                            <div className="z-20 flex items-center order-1 rounded-full max-md:min-w-[60px] duration-1000 group-hover:scale-[1.1] max-md:group-hover:scale-[1]">
                                <img className='w-[60px] h-[60px]' src={ImageIcons.coreicon2} alt='' />
                            </div>
                            <div className="relative md:order-1 max-md:order-2 bg-[#123A6D] md:w-5/12 max-md:w-full max-md:ms-[20px] px-6 py-[35px] before:content-[''] before:absolute before:w-[0px] before:h-[0px] before:left-[-18px] before:top-[50%] before:translate-y-[-50%] before:border-t-[15px] before:border-t-[transparent] before:border-solid before:border-b-[15px] before:border-b-[transparent] before:border-r-[20px] before:border-r-[#123A6D]">
                                <h3 className="text-white text-[23px] leading-[32px] font-bold mb-3">Customer-Centric Excellence</h3>
                                <p className="text-white text-[16px] leading-[24px] font-normal tracking-wide mb-0">We, at VP Group, have an aim of exceeding the expectations of the customers and this ambition encourages us to focus on the customer satisfaction, reliability, and high-quality service. We value the reviews of our customers, as their feedback helps us to improve our services.</p>
                            </div>
                        </div>
                        <div className="md:mt-[-50px] flex md:justify-between items-center w-full left-timeline md:flex-row-reverse max-md:mb-[15px] group">
                            <div className="max-md:hidden order-1 w-5/12"></div>
                            <div className="z-20 flex items-center order-1 rounded-full max-md:min-w-[60px] duration-1000 group-hover:scale-[1.1] max-md:group-hover:scale-[1]">
                                <img className='w-[60px] h-[60px]' src={ImageIcons.coreicon3} alt='' />
                            </div>
                            <div className="relative md:order-1 max-md:order-2 bg-[#6A79FF] md:w-5/12 max-md:w-full max-md:ms-[20px] px-6 py-[35px] before:content-[''] before:absolute before:w-[0px] before:h-[0px] md:before:right-[-18px] max-md:before:left-[-18px] before:top-[50%] before:translate-y-[-50%] before:border-t-[15px] before:border-t-[transparent] before:border-solid before:border-b-[15px] before:border-b-[transparent] md:before:border-l-[20px] md:before:border-l-[#6A79FF] max-md:before:border-r-[20px] max-md:before:border-r-[#6A79FF]">
                                <h3 className="text-white text-[23px] leading-[32px] font-bold mb-3">Sustainability and Responsibility</h3>
                                <p className="text-white text-[16px] leading-[24px] font-normal tracking-wide mb-0">We ensure eco-friendliness in our operations, working to decrease our carbon footprints so that we can support a cleaner and greener future. The onus of any impact of our products and services lies completely on us.</p>
                            </div>
                        </div>
                        <div className="md:mt-[-50px] flex md:justify-between items-center w-full right-timeline group">
                            <div className="max-md:hidden order-1 w-5/12"></div>
                            <div className="z-20 flex items-center order-1 rounded-full max-md:min-w-[60px] duration-1000 group-hover:scale-[1.1] max-md:group-hover:scale-[1]">
                                <img className='w-[60px] h-[60px]' src={ImageIcons.coreicon4} alt='' />
                            </div>
                            <div className="relative md:order-1 max-md:order-2 bg-[#D201FE] md:w-5/12 max-md:w-full max-md:ms-[20px] px-6 py-[35px] before:content-[''] before:absolute before:w-[0px] before:h-[0px] before:left-[-18px] before:top-[50%] before:translate-y-[-50%] before:border-t-[15px] before:border-t-[transparent] before:border-solid before:border-b-[15px] before:border-b-[transparent] before:border-r-[20px] before:border-r-[#D201FE]">
                                <h3 className="text-white text-[23px] leading-[32px] font-bold mb-3">Collaboration and Integrity</h3>
                                <p className="text-white text-[16px] leading-[24px] font-normal tracking-wide mb-0">Transparency, honesty and ethical behaviour are our guiding principles, as we believe in building strong bonds with our employees, customers and partners. Thereby, we give preference to open communication and teamwork so that our goals can be achieved together.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CoreValues
