import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import Button from '../shared/button';
const About = () => {
  return (
    <section className='relative overflow-hidden'>
      <div className='container '>
        <div className='flex lg:flex-nowrap flex-wrap items-center'>
          <div className='md:min-h-[600px] sm:min-h-[500px] min-h-[350px] relative lg:w-[50%] w-full'>
            <img className='object-cover h-full w-full absolute rounded-[10px]' src={ImageIcons.aboutSectionImg} alt='' />
          </div>

          <div className='lg:w-[50%] w-full xl:pl-[50px] lg:pl-[40px] relative max-lg:mt-[40px] max-sm:mt-[30px]' >
            <div className='relative z-10 lg:max-w-[700px] max-w-full'>
              <h2 className='uppercase mb-[10px]'>About Us</h2>
              <p>Welcome to VP Group, which offers users with a broad assortment of services like IPTV, Cloud storage & CCTV Surveillance, Colocation, Telecommunication, Broadcasting, Real Estate, Aviation, Entertainment et cetera. We are always committed towards innovation and sustainability and it encourages us to provide smooth, safe and advanced solutions for the daily varying needs of different customers. We ensure providing users with fast  and reliable internet connections via our brands such as myInternet, myWifi and VP Broadband. Along with this, our broadcasting solutions allow users to immerse themselves into the world of digital entertainment, as we offer a wide array of content and programmes to our users, ensuring that they always have something new to watch. Besides this, we are also committed towards promoting sustainability and supporting your IT infrastructure through our myEV point and The Abacus cloud solution ventures. We are not only limited to this but we also provide 3 BHK independent flats, security solutions and colocation services via our ventures named Angan homes, MyCloudCam and Velosting. We also offer many other services to our users via our brands like Air Ambulance, myJet, Ashke TV,  and Immigration Timez. We encourage you to join us and shape a brighter digital future for India and beyond.</p>

              <Button title="Learn More" hrefLink="/about-us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About