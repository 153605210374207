import React from 'react';
import { Link } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';
import { LuArrowUpRight } from "react-icons/lu";


const Button = ({ title = "", hrefLink = "" }) => {
    return (
        <Link to={hrefLink} onClick={windowScroll} className="relative overflow-hidden z-10 px-[40px] text-[18px] py-[16px] font-medium text-[#fff] bg-[#123A6D] hover:text-[#fff] th-btn inline-flex justify-center items-center">
            {title}
            <div class="icon ml-[10px]"><LuArrowUpRight className='text-[20px] btn_arrow' /></div>
        </Link>
    )
}

export default Button;