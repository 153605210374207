import React, { useEffect, useState } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import stdCodes from "../components/utils/data/stdCodes.json";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { TbMailOpened } from "react-icons/tb";
import { GrMap } from "react-icons/gr";
import { PostRequest } from '../components/utils/request';
import { validateEmail } from '../components/utils/formFunctions';
import Loader from '../components/shared/loader';
import { windowScroll } from '../components/utils/windowScroll';
import { Link, useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();

  const [error, setError] = useState({
    name: false,
    email: false,
    phone: false,
    subject: false,
    msg: "",
    message: false,
    success: false
  });

  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    std: "+91",
    message: ""
  })

  const [disabled, setDisabled] = useState(false);

  const firstNameHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Allow spaces

    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) => match.toUpperCase());

    if (capitalizedValue.length <= 30) {
      setFormInput((prevState) => ({ ...prevState, name: capitalizedValue }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormInput((prevState) => ({ ...prevState, phone: numericValue }));
      setError((prevState) => ({ ...prevState, phone: false, message: '' }));
    }
  };

  const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;

  const submitHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    setError((prevState) => ({ ...prevState, message: "" }))

    if (!formInput.name || !formInput.email || !formInput.phone || !formInput.subject || !formInput.message) {
      setError((prevState) => ({ ...prevState, success: false, message: "Fields must not be empty" }))
      setDisabled(false);
    }
    else if (!validateEmail(formInput.email)) {
      setError((prevState) => ({
        ...prevState, email: true, message: "Email is invalid"
      }))
      setDisabled(false);
    }
    else if (!validRegex.test(formInput.phone)) {
      setError((prevState) => ({
        ...prevState, phone: true, message: "phone number is invalid"
      }))
      setDisabled(false);
    }
    else {
      PostRequest(`${process.env.REACT_APP_URL}/user/mail`, {
        name: formInput.name,
        email: formInput.email,
        phone: `${formInput.std} ${formInput.phone}`,
        subject: formInput.subject,
        message: formInput.message
      })
        .then((response) => {
          console.log(response)
          setError((prevState) => ({ ...prevState, success: true }));
          setError((prevState) => ({ ...prevState, success: false }));
          setDisabled(false);
          setFormInput({
            name: "",
            email: "",
            phone: "",
            message: "",
            subject: "",
            std: "+91",
          });
          navigate("/thank-you");
          windowScroll();

        })
        .catch((error) => {
          setError((prevState) => ({ ...prevState, success: true }));
          setDisabled(false);
        });
      }
    }

    // useEffect(() => {
    //   if (error.success) {
    //   }
    // })

  return (
    <Layout>
      <InnerPageBanner title="Contact Us" innerPageBanner={ImageIcons.contactBg} />

      <section className='bg-[#f5f8fd] md:pb-0 md:-mb-[100px]'>
        <div className='container relative z-20'>
          <div className="lg:p-[40px] md:p-[30px] p-0 md:shadow-[0_0_10px_#e5e5e5] md:bg-white relative">
            {disabled && (<Loader />)}
            <h3 className="text-[#33345E]  mb-[20px]">Send Us A Message</h3>
            <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${error.message ? 'block' : 'hidden'}`}>{error.message}</p>
            <div className="grid md:grid-cols-2 gap-[25px]" id="contactusForm">
              <div>
                <label className="inline-block text-[#33345E] font-medium mb-[10px]" htmlFor="name" >Name <span className="text-[#ff0000]">*</span></label>
                <input name="name" id="name" value={formInput.name} onChange={firstNameHandler} className="h-[55px] bg-white w-full focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] rounded-[5px] focus:shadow-[0_0_2px_#6a79ff] text-[16px] " placeholder="Enter Your Name" required={true} />
              </div>

              <div>
                <label className="inline-block text-[#33345E] font-medium mb-[10px]" for="email">Email Address<span className="text-[#ff0000]">*</span></label>
                <input type="email" name="email" id="email" value={formInput.email} className="h-[55px] bg-white w-full focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] rounded-[5px] focus:shadow-[0_0_2px_#6a79ff] text-[16px]" placeholder="Enter Your Email Address" required={true}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, email: e.target.value }));
                    setError(prevState => ({ ...prevState, email: false, message: "" }));
                  }}

                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>

              <div>
                <label className="inline-block text-[#33345E] font-medium mb-[10px]" for="phone" >Phone Number<span className="text-[#ff0000]">*</span></label>
                <div className="flex">
                  <select name="stdCode" id="stdCode" className="border-r-0 rounded-l-[5px] h-[55px] bg-white w-[80px] focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] focus:shadow-[0_0_2px_#6a79ff] text-[16px]"
                    value={formInput.std}
                    onChange={(e) =>
                      setFormInput((prevState) => ({
                        ...prevState,
                        std: e.target.value,
                      }))
                    }>
                    {stdCodes.map((item, i) => (
                      <option
                        key={i} value={item.dial_code}
                      >
                        {`${item.dial_code} ${item.code} `}
                      </option>
                    ))}
                  </select>
                  <input type="tel" name="phone" id="phone" value={formInput.phone} onChange={handlePhoneInputChange}
                    className="h-[55px] bg-white w-full focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] rounded-[5px] rounded-l-none focus:shadow-[0_0_2px_#6a79ff] text-[16px]" placeholder="Enter Your Phone Number"
                  />
                </div>
              </div>

              <div>
                <label className="inline-block text-[#33345E] font-medium mb-[10px]" htmlFor="subject">Subject <span className="text-[#ff0000]">*</span></label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={formInput.subject}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, subject: e.target.value }));
                    setError(prevState => ({ ...prevState, subject: false, message: "" }));
                  }}
                  className="h-[55px] bg-white w-full focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] rounded-[5px] focus:shadow-[0_0_2px_#6a79ff] text-[16px]"
                  placeholder="Enter Your Subject"
                />
              </div>

              <div className="sm:col-span-2 col-span-1">
                <label className="inline-block text-[#33345E] font-medium mb-[10px]" for="message">Message <span className="text-[#ff0000]">*</span></label>
                <textarea
                  className="h-[50px] w-full focus:outline-none hover:border-[#123a6d] border-[#c4c4c4] px-[15px] border focus:border-[#6a79ff] rounded-[5px] focus:shadow-[0_0_2px_#6a79ff] py-[12px] text-[16px] min-h-[80px] resize-none false"
                  name="message"
                  id="message"
                  value={formInput.message}
                  placeholder="Message"
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, message: e.target.value }));
                    setError(prevState => ({ ...prevState, msg: false, message: "" }));
                  }}
                  rows="4"
                  cols="30"
                ></textarea>
              </div>

              <div>
                <button type="submit" className="bg-[#123a6d] duration-500 text-white px-[40px] h-[55px] leading-[45px] inline-block rounded-[8px] hover:bg-[#6a79ff] uppercase font-medium" onClick={submitHandler}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-[#fff] md:pt-[180px]'>
        <div className="container">
          <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-3">
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#6a79ff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 hover:bg-[#6a79ff]">
              <TbMailOpened className='!text-[60px] md:!text-[70px] text-[#6a79ff] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0 group-hover:text-[#fff] duration-700">Email</h5>
              <Link to={`mailto:info@theabacus.in`} className="font-medium hover:text-[#6a79ff] group-hover:text-[#fff] duration-700">{data.email}</Link>
            </div>
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#6a79ff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 hover:bg-[#6a79ff]">
              <LiaPhoneVolumeSolid className='!text-[60px] md:!text-[70px] text-[#6a79ff] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0 group-hover:text-[#fff] duration-700">Phone</h5>
              <Link to={`tel:+91 ${data.phone}`} className="font-medium group-hover:text-[#fff] duration-700">{data.phone}</Link>
            </div>
            <div className="group flex border-[2px] border-[#ededed] hover:border-[#6a79ff] flex-col items-center gap-3 px-[15px] py-[40px] justify-center text-center duration-700 hover:bg-[#6a79ff]">
              <GrMap size="60px" className='!text-[60px] md:!text-[70px] text-[#6a79ff] group-hover:text-[#fff] group-hover:scale-[1.08] duration-700' />
              <h5 className="text-[22px] md:text-[26px] font-bold mb-[5px] mt-0 group-hover:text-[#fff] duration-700">Location</h5>
              <div className="font-medium group-hover:text-[#fff] duration-700">{data.address}</div>
            </div>
          </div>
        </div>
      </section>

      <div className='map'>
        <iframe
          title='map'
          width="100%"
          className='min-h-[100%] h-[350px] md:h-[500px] bg-[#e8eaed]'
          height=""
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.180033914094!2d76.69176177607471!3d30.713338786568798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef99cc17fe25%3A0x6a593a19f7208f9b!2sAbacus%20Cloud!5e0!3m2!1sen!2sus!4v1705467957769!5m2!1sen!2sus"
        ></iframe>
      </div>
    </Layout>
  )
}

const data = {
  email: "info@theabacus.in",
  phone: "+91 9851198555",
  address: "B-70, Industrial Area, Phase 7, Sahibzada Ajit Singh Nagar, Punjab 160055"
}

export default ContactUs
