import * as React from "react"
import Layout from "../components/shared/layout"
import Button from "../components/shared/button"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const Thanks = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/contact-us");
        }, 2000);
    })

    return (
        <>
            <Layout menuColor="[#fff]">
                <section className="bg-[linear-gradient(0deg,#D400FE_0%,#1036DE_100%)]">
                    <div className="container">
                        <div className="text-center lg:mt-[107px] mt-[80px]">
                            <h1 className="font-[cursive] thanks lg:text-[80px] sm:text-[70px] text-[45px] uppercase md:mb-[20px] text-white">Thank You</h1>

                            <IoIosCheckmarkCircleOutline className="mx-auto sm:text-[70px] text-[50px] mb-[20px] text-[#05cf05]" />

                            <p className="text-white mb-[30px]">Your submission is received and we will contact you soon</p>

                            <Button hrefLink='/' title='Return to Home' />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Thanks