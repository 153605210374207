import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Team from '../components/home/team';

const AboutUs = () => {
  return (
    <Layout>
      <InnerPageBanner title="About Us" innerPageBanner={ImageIcons.contactBg} />

      <section>
        <div className='container'>
          <div className='flex lg:flex-nowrap flex-wrap gap-[40px] items-center'>
            <div className='relative grid grid-cols-2 gap-[20px] lg:w-[40%] w-full max-w-[600px] mx-auto'>
              <img className='absolute top-0 left-0 right-0 m-auto -z-10 max-w-[70%] dance3' src={ImageIcons.aboutBgShape} alt='' />

              <div>
                <img className='w-full rounded-[10px]' src={ImageIcons.about1} alt='' />
              </div>

              <div className='mt-[80px]'>
                <img className='w-full rounded-[10px]' src={ImageIcons.about2} alt='' />
              </div>
            </div>

            <div className='lg:w-[60%] w-full'>
              <p>VP group is a preeminent conglomerate at the pinnacle of digital revolution. We have a large portfolio of ventures, offering a wide range of services to people. Our dynamic portfolio include Internet Services Providers operations, IPTV services, cloud storage & cloud surveillance solutions, Data centers et cetera. Our other sectors of service include Telecommunication, Broadcasting, IT infrastructure, real estate, Surveillance, Aviation and Entertainment, thereby covering the broad area of digital solutions.</p>
              <p>We are always devoted to providing smooth and secure solutions for your everyday needs, as with our telecommunication brands like myInternet, myWifi, and VP Broadband, we offer our users with high-speed and reliable internet connection. Besides this, our broadcasting services include Skypro IPTV, where users are provided with a broad assortment of content, thereby, accelerating their entertainment streaming experience. Being environmentally conscious, we always want to promote sustainability, therefore we also run a venture of myEVpoint where we provide EV charging stations to promote the usage of electric vehicles.</p>
              <p className='mb-0'>Following this, TheAbacus supports your IT infrastructure, saving efforts and expenses of buying and managing your own infrastructure. We have also come up with cloud surveillance services, where users are offered with state-of-the-art  cloud based solutions to ensure their safety and security. At Aangan Homes, which is a real estate company, we are devoted to providing you with the best solutions by providing you help related to buying or selling a home, apartment or any commercial site. Our other ventures are in the Aviation and Entertainment department in the name of myAirAmbulance and myJet, and for entertainment, the ventures are Ashke TV and Immigration Timez, where audiences across the globe are entertained with unique content.</p>
            </div>
          </div>
        </div>
      </section>

      {/* <Counting /> */}

      <section className='pt-0'>
        <div className='container'>
          <div className='grid md:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-[20px] text-center'>
            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#1136df] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] md:p-[30px] p-[20px]'>
              <div className='w-[100px] md:h-[70px] h-[50px] mb-[15px] mx-auto'>
                <img src={ImageIcons.vision} className='h-full w-full object-contain' alt='' />
              </div>
              <h2>Vision</h2>
              <p className='mb-0'>VP Group has a vision to be the leader in technological innovation and in the creation of the future, where all the possibilities of the digital age are redefined via seamless connectivity and sustainable solutions. We are committed to create a world, where all our services be it telecommunications, broadcasting, cloud storage, EV charging or aviation, are enhancing the daily lives of our users, making them able to achieve more in their life. Our vision of leading with advanced technology and sustainable practices helps us make a greener and more connected world where each of our services ensure contributing to a sustainable future.</p>
            </div>

            <div className='shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] hover:border-b-[#1136df] border-b-[4px] border-white hover:shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] duration-700 rounded-[10px] md:p-[30px] p-[20px]'>
              <div className='w-[100px] md:h-[70px] h-[50px] mb-[15px] mx-auto'>
                <img src={ImageIcons.mission} className='h-full w-full object-contain' alt='' />
              </div>
              <h2>Mission</h2>
              <p className='mb-0'>Our VP Group has a mission of progress by focusing on innovation and sustainability. As our team at VP Group is committed to create a culture of creativity, integrity and customer-oriented excellence, we always give priority to the requirements and contentment of our customers. Moreover, the commitment of our team towards offering exceptional value and building a lasting difference in the communities we are serving, always encourages us to grow strong partnerships and exceed expectations.</p>
            </div>

          </div>
        </div>
      </section>

      <Team />

    </Layout>
  )
}


export default AboutUs