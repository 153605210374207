import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';

const Innovation = () => {
  return (
    <section className='relative bg-center bg-fixed bg-cover bg-no-repeat after:content-[""] after:absolute after:bg-[linear-gradient(0deg,#D400FE_0%,#1036DE_100%)] after:top-0 after:left-0 after:w-full after:h-full after:opacity-75' style={{ backgroundImage: `url(${ImageIcons.innovation})` }}>
      <div className='container relative z-10 md:py-[100px] py-[50px]'>
        <h2 className='text-white max-w-[850px] mx-auto text-center mb-0'>There are no limitations in the world of technology, as there exist only possibilities. Let’s explore them together.</h2>
      </div>
    </section>
  )
}

export default Innovation