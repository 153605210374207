import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaMicrophone, FaRegCalendarAlt, FaTwitter } from 'react-icons/fa';
import { RiArrowRightLine } from 'react-icons/ri';
import { FaUser } from "react-icons/fa";
import { IoSearch } from 'react-icons/io5';

const BlogDetails = () => {
  return (
    <Layout>
      <InnerPageBanner title="Blog Details" innerPageBanner={ImageIcons.contactBg} />

      <section className='pb-0'>
        <div className='container'>

          <div className='flex max-lg:flex-wrap justify-between xl:gap-[40px] gap-[30px]'>

            <div className='lg:w-[70%] w-full' >
              <div className='mb-[30px] xl:h-[500px] lg:h-[450px] md:h-[400px] rounded-[10px] overflow-hidden'>
                <img className='w-full h-full object-cover object-center min-h-[200px]' src={ImageIcons.innovation} alt='' />
              </div>
              <div className='mb-[30px] flex items-center justify-between border-b pb-[25px] border-[#ddd]'>
                <div className='flex items-center'><FaUser className='inline-block text-[#123a6d] mr-[8px]' /> Rahul</div>
                <div className='flex items-center'><FaRegCalendarAlt className='inline-block text-[#123a6d] mr-[8px]' /> 2024-06-15</div>
              </div>
              <h1>Empowering a Connected World: The Future of Digital Experiences</h1>
              <p>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>
              <p>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>

              <i className='!mt-0 md:m-[25px] m-[10px] md:pl-[25px] pl-[10px] md:py-[10px] py-[6px] inline-block md:border-l-4 border-l-2 font-bold border-[#D400FE] md:text-[18px] text-[17px]'>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</i>

              <p>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>
              <p>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>

              <h2>Strive for continuous innovation in technology and services.</h2>

              <p>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>
              <p className='mb-0'>Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.</p>

            </div>

            <div className='lg:w-[30%] w-full'>
              <div className='mb-[30px] bg-[#123a6d] xl:px-8 xl:py-5 max-xl:px-5 max-xl:py-5 rounded-[10px] shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)]'>
                <div className="relative rounded-[3px] max-md:w-full" >
                  <input
                    type="search"
                    placeholder="Search...."
                    className="h-[42px] pr-[35px] focus:outline-none w-full bg-transparent text-white placeholder:text-white" />

                  <button type="button" className="h-[42px] w-[25px] text-white absolute top-0 right-0 flex justify-center items-center ">
                    <IoSearch className="text-[25px]" /></button>
                </div>
              </div>

              <div className='mb-[30px] xl:px-8 xl:py-8 max-xl:px-5 max-xl:py-5 rounded-[10px] shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)]'>
                <div className='title-sidebar mb-5'>
                  <h4>Recent Post</h4>
                  <hr className="green-hr-line" />
                </div>

                {blogsData.map((item, i) => (
                  <Link
                    className={`mt-[30px] flex group`}
                    to=''
                  >
                    <div className={`flex justify-center items-center h-[90px] w-[90px] duration-500 relative rounded-[2px] overflow-hidden group-hover:after:absolute after:content-[''] after:w-full after:h-full after:left-0 after:top-0 after:bg-[linear-gradient(0deg,#D400FE_0%,#1036DE_100%)] after:duration-500 after:opacity-50 `}>
                      <img className={`h-full w-full object-cover object-center duration-500 group-hover:scale-110 `} src={item.blogImage}
                        alt={item.altText} />
                    </div>
                    <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] `}>
                      <div className='bg-[#123a6d] text-white py-[5px] px-[15px] rounded-[8px] text-[15px] inline-block mb-[5px]'>{item.publishedAt}</div>
                      <h5 className="mb-0 text-[18px] leading-[24px] line-clamp-2">{item.title}</h5>
                    </div>
                  </Link>
                ))}
              </div>

              <div className='mb-[30px] xl:px-8 xl:py-8 max-xl:px-5 max-xl:py-5 rounded-[10px] shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)]'>
                <div className='title-sidebar mb-5'>
                  <h4>Social Media</h4>
                  <hr className="green-hr-line" />
                </div>
                <div className="flex flex-wrap">
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] rounded-full bg-[#123a6d] hover:bg-[#1834df] flex justify-center items-center'> <FaFacebookF /></Link>
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] rounded-full bg-[#123a6d] hover:bg-[#1834df] flex justify-center items-center'> <FaTwitter /></Link>
                  <Link to='#' target="_blank" className='text-[18px] mr-[15px] text-[#fff] hover:text-[#fff] w-[40px] h-[40px] rounded-full bg-[#123a6d] hover:bg-[#1834df] flex justify-center items-center'> <FaLinkedinIn /></Link>
                </div>
              </div>

              <div className='box-shodow-info rounded-[10px] overflow-hidden shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] sticky top-5'>
                <div className='contact-sidebar-info'>
                  <div className='contact-img-gril bg-[#000]'>
                    <img src={ImageIcons.helpImg} alt='blog' className='w-full object-cover object-top h-[400px]' />
                  </div>
                  <div className=' bg-[linear-gradient(-6deg,#D400FE_30.9%,#1036DE_75.2%,transparent_75.5%)] linear-gradient(-6deg, #0b7132 74.9%, #fff0 75.2%) relative text-center xl:px-10 xl:pb-10 max-xl:px-8 max-xl:pb-8 mt-[-80px]'>
                    <div className='relative z-20 w-[90px] h-[90px] mx-auto bg-[#fff] flex items-center justify-center rounded-full'>
                      <FaMicrophone className='text-[#123a6d] text-[35px]' />
                    </div>
                    <h4 className='mb-3 mt-4 text-white font-medium'>Do you need any help?</h4>

                    <p className='font-medium text-white cursor-pointer mb-[5px]'> <Link to="tel:9876543210" className='hover:underline'>9876543210</Link></p>
                    <p className='font-medium text-white cursor-pointer mb-0'><Link to="mailto:hello@theabacus.in" className='hover:underline'>hello@theabacus.in</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <h2 className='text-center mb-[30px]'>Latest Blog Posts</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[20px] xl:gap-[30px]">
            {blogsData.map((item, i) => (
              <Link key={i} to={item.handle} className='flex flex-col overflow-hidden bg-[#fff] group rounded-[10px] duration-500 shadow-[0px_0px_10px_rgba(0,0,0,0.09)] justify-start'>
                <div className='relative h-[280px] w-full overflow-hidden'>
                  <img
                    src={item.blogImage}
                    alt={item.altText}
                    className='w-full h-full object-center object-cover '
                  />
                </div>
                <div className='md:p-[25px] p-[20px]'>
                  <ul className='mb-[20px] flex items-center'>
                    <li className='flex items-center text-[15px] mr-[15px]'><FaUser className='inline-block text-[#123a6d] mr-[8px]' /> {item.author}</li>
                    <li className='flex items-center text-[15px]'><FaRegCalendarAlt className='inline-block text-[#123a6d] mr-[8px]' /> {item.publishedAt}</li>
                  </ul>
                  <div>
                    <h4 className='text-[20px] ine-clamp-2 mb-[15px] group-hover:text-[#6A79FF] duration-500 font-semibold leading-[28px]'>{item.title}</h4>
                    <p className='mb-[15px] relative line-clamp-3'>{item.description}</p>
                    <span className='text-[#000] group-hover:text-[#D400FE] duration-500'>Read More <RiArrowRightLine className='inline-block' /></span>
                  </div>

                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

const blogsData = [
  {
    title: "Empowering a Connected World: The Future of Digital Experiences",
    handle: "/blog-details",
    blogImage: ImageIcons.blog1,
    altText: "Blog image",
    author: "Ram",
    publishedAt: "23 Feb, 2024",
    description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
  },
  {
    title: "Seamless Connectivity: Pioneering the Next Wave of Digital Solutions",
    handle: "/blog-details",
    blogImage: ImageIcons.blog1,
    altText: "Blog image",
    author: "Mohan",
    publishedAt: "23 Feb, 2024",
    description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
  },
  {
    title: "The Future is Now: Innovations in OTT, Cloud Surveillance, and Data Centers",
    handle: "/blog-details",
    blogImage: ImageIcons.blog1,
    altText: "Blog image",
    author: "Rahul",
    publishedAt: "23 Feb, 2024",
    description: "Strive for continuous innovation in technology and services. Embracechange and adapt quickly to evolving industry trends and customerneeds. Foster a culture that encourages creativity and problemsolving.",
  }
]

export default BlogDetails