import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';

const Accreditations = () => {

  const images = [
    {
      image: ImageIcons.certificate1,
      alt: "Certificate 1"
    },
    {
      image: ImageIcons.certificate2,
      alt: "Certificate 2"
    },
    {
      image: ImageIcons.certificate3,
      alt: "Certificate 3"
    },
    {
      image: ImageIcons.certificate4,
      alt: "Certificate 4"
    },
    {
      image: ImageIcons.certificate5,
      alt: "Certificate 5"
    },
    {
      image: ImageIcons.certificate6,
      alt: "Certificate 6"
    },
  ];

  return (
    <Layout>
      <InnerPageBanner title="Accreditations" innerPageBanner={ImageIcons.contactBg} />

      <section className='relative dis-features-section bg-auto bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.featurebgimg}), url(${ImageIcons.feashape})` }}>
        <div className='container'>
          <div className='text-center mx-auto max-w-[1100px] mb-[40px]'>
            <h3 className='mb-[15px] font-bold'>VP Group believes in delivering quality and that’s why our work has been recognized globally with many accreditations over the years. </h3>
            <h5 className='text-[20px]'>These recognitions shows our dedication towards offering quality services.</h5>
          </div>
          <div className='grid lg:grid-cols-3 grid-cols-2 lg:gap-[40px] md:gap-[30px] sm:gap-[20px] gap-[10px]'>
            <LightgalleryProvider>
              {images.map((item, index) => (
                <LightgalleryItem key={index} src={item.image} className="border-4 border-[#fff]">
                  <img className='w-full object-cover object-center cursor-pointer' src={item.image} alt={item.alt} />
                </LightgalleryItem>
              ))}
            </LightgalleryProvider>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default Accreditations;