import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';


const TermsAndConditions = () => {
  return (
    <Layout>
      <InnerPageBanner title="Terms and Conditions" innerPageBanner={ImageIcons.contactBg} />

      <section>
        <div className='container'>
          <p>Welcome to VP Group. We will outline our rules and regulations regarding the usage of our website in these terms and conditions.</p>
          <p>If you access our website, you will show your acceptance regarding our terms and conditions. We ask you to not to use or access our services if you have any of the issues or do not agree to our terms and conditions.</p>

          <h2>Terminology</h2>
          <p>Below given is the clarification regarding certain terms used in our terms and conditions. The terms <strong>Client, You, and Your</strong> refer to you or the person using the services. The term <strong>company</strong> refers to <strong>we, our and us</strong> and the <strong>terms Party, Parties</strong> or Us refer to both <strong>our clients and ourselves.</strong></p>

          <h2>Cookies</h2>
          <p>We use cookies and if you use our services, you agree to use cookies in agreement with the privacy policy of VP Group.</p>

          <h2>Licence</h2>
          <p>VP Group and its licensors reserve intellectual property rights regarding the material on the VP Group. It can be accessed from VP Group for the purpose of personal use but it is subjected to restrictions which are set in these terms and conditions.</p>

          <h3>You are not allowed to do the following</h3>
          <ul className='list'>
            <li>You can not reprint, redistribute and reuse any of the materials from VP Group.</li>
            <li>You are also not allowed to indulge in selling, renting and sub-licencing the material from VP Group.</li>
            <li>Moreover, you are also not allowed to reproduce, duplicate any material from the VP Group.</li>
          </ul>

          <h3>User Comments</h3>
          <p>The website of the VP Group provides an opportunity for the users to post and exchange opinion and information in some particular areas of the website.  The comments are not filtered, edited, published or reviewed before their presence on the website of VP Group and it is important to note that no views and opinions are reflected about VP Group  by these comments. Moreover, VP Group has all the rights to keep tabs on the comments and remove those which are inappropriate, offensive, or lead to breach of the terms and conditions.</p>

          <h3>You Warrant and Represent That</h3>
          <ul className='list'>
            <li>You are allowed to post the comments on the website of VP Group and have all the required licensing and consents to do so.</li>
            <li>Your comments are not invading any rights without limitation of copyright, patent or trademark of any of the third parties.</li>
            <li>Your comments are not containing any offensive or unlawful material which may be considered as an invasion of privacy.</li>
            <li>These can not be utilised for the purpose of promoting business or presenting commercial activities or unlawful activity.</li>
          </ul>

          <p>
            <i>We reserve all the rights to reproduce, edit, use and reproduce any of the comments in any of the form, format and media and you also entitle us to do the same. We can make changes in our terms and conditions at any time, so we encourage you to read our terms and conditions from time to time.</i>
          </p>

        </div>
      </section>

    </Layout>
  )
}


export default TermsAndConditions