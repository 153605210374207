import React, { useState, useEffect } from 'react';
// import Button from '../shared/button';
// import sss from "../../assets/images/logo.svg";
import videoFile from '../../assets/images/banner_bg.mp4';
import { windowScroll } from '../utils/windowScroll';
import { Link } from 'react-router-dom';
import { LuArrowUpRight } from 'react-icons/lu';

const Hero = () => {
  const phrases = ['TheAbacus', 'MyEVpoint', 'MyCloudCam', 'MyInternet', 'Skypro', 'MyPunjabi', 'MyHindi', 'Ashke', 'Angan Homes'];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    const currentPhrase = phrases[currentPhraseIndex];

    const typeNextCharacter = () => {
      setDisplayedText(prevText => {
        const nextCharacter = currentPhrase[prevText.length];
        if (nextCharacter) {
          return prevText + nextCharacter;
        } else {
          // Move to the next phrase when the current one is complete
          setTimeout(() => {
            setDisplayedText('');
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
          }, 200); // Delay before starting the next phrase
        }
        return prevText;
      });
    };

    const typingInterval = setInterval(typeNextCharacter, 300); // Adjust the typing speed here

    return () => clearInterval(typingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhraseIndex]);

  return (
    <section className="banner relative h-[100vh] flex items-center justify-center flex-col text-center after:content-[''] after:absolute after:bg-[linear-gradient(0deg,#D400FE_0%,#1036DE_100%)] after:top-0 after:left-0 after:w-full after:h-full after:opacity-75 after:z-10">
      <video className='w-full h-full object-cover absolute top-0 left-0' autoPlay muted playsInline loop>
        <source src={videoFile} type="video/mp4" />
      </video>
      <div className='container relative z-20 pt-[80px]'>
        <div className='text-white md:text-[20px] text-[18px] font-medium p-[12px] bg-[rgba(16,54,222,0.39)] inline-block uppercase mb-[10px]'>Empowering Businesses with Innovative Solutions</div>
        <h1 className='text-white'>VP Group Of Companies {displayedText}</h1>
        <p className='text-white mb-[30px]'>Digitax India Communications Private Limited</p>
        {/* <Link to='/contact-us' onClick={windowScroll} className="px-[50px] text-[18px] py-[16px] font-medium text-[#123A6D] bg-[#fff] rounded-[30px] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.25)] hover:text-[#fff] hover:bg-[#1036de] ease inline-block">Contact Us</Link> */}
        <Link to='/contact-us' onClick={windowScroll} className="rounded-[30px] relative overflow-hidden z-10 px-[40px] text-[18px] py-[16px] font-medium bg-[#fff] text-[#123A6D] hover:text-[#fff] th-btn inline-flex justify-center items-center">
          Contact Us
          <div class="icon ml-[10px]"><LuArrowUpRight className='text-[20px] btn_arrow' /></div>
        </Link>
      </div>

    </section>
  )
}

export default Hero