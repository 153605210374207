import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';


const PrivacyPolicy = () => {
  return (
    <Layout>
      <InnerPageBanner title="Privacy Policy" innerPageBanner={ImageIcons.contactBg} />

      <section>
        <div className='container'>
          <p>Welcome to VP Group, where your privacy is taken seriously. Here we will provide you all the information related to collection, usage and sharing of your information, so we encourage you to read our privacy policy.</p>

          <p>Kindly read this information carefully before using our services. When you use or access our products and services in any way, you automatically show your consent to our privacy policy. Our privacy policy can be updated any time and your continued engagement after the changes will indicate that you are in complete agreement to our policy, so we encourage you to keep close tabs on our privacy policy from time to time.</p>

          <h2>What Products and Services Does this Policy Cover?</h2>
          <p>Following are the details regarding our products and services.</p>

          <ul className='list'>
            <li>Hardware, firmware, and software products.</li>
            <li>Services, including on and through the products. Technical support and other services which are accessed via our site.</li>
            <li>Software installed to the smartphone or any other product to have access to our services.</li>
            <li>Subscription services which are accessed using Web apps or mobile apps.</li>
          </ul>

          <h3>Your Information We Collect</h3>
          <p>Different types of data are collected by us from and about you. The details are given below</p>

          <h2>1. Personal Information Provided to Us</h2>
          <p>Information is provided by you to us when you-</p>
          <ul className='list'>
            <li>Search for services on our site.</li>
            <li>Place an order.</li>
            <li>Stream, download or view content on the devices.</li>
            <li>Provide information in the account or your profile.</li>
            <li>Provide access permission.</li>
            <li>Communicate via phone, e-mail, or by any other method.</li>
            <li>Fill a questionnaire and content app form.</li>
            <li>Upload images, videos or other files to the cloud.</li>
          </ul>

          <h3>While you perform such actions, you provide us with the following information</h3>
          <ul className='list'>
            <li>Your name, address, phone number and email address.</li>
            <li>Payment information.</li>
            <li>Location information.</li>
            <li>Livechat,  posts and emails.</li>
            <li>Personal description and photograph in your profile.</li>
          </ul>

          <h2>2. Automatic Information</h2>
          <p>Your personal information is being collected automatically by us while you access our services. When hardware devices and and third-party services are connected to your account, usage information is being reported by these hardware devices, and the information may be read, processed and stored by the services for the purposes which are mentioned in our privacy policy.  This type of information is usually associated with the utilisation of a particular hardware device or any of the third-party services.</p>

          <p>The information regarding the way you interact with our services is also collected by us and this includes the accessed pages and links which you clicked on our site, your IP, MAC address and other information associated with.Devices’ browser history along with access time are also collected.</p>

          <h2>3. Information from Other Sources</h2>
          <p>Your data can also be received by us from other sources like third-party service providers which have been integrated by you into our products and services. The privacy policies of third-party providers should be reviewed by you properly and your decision will be considered in order to use third-party products and services with our products and services so that we can have your consent regarding the policies of the third-party provider.practices.</p>

          <h2>4. How we use your Information</h2>

          <p>Your personal information is collected by us in order to operate, provide, and improve our services. The purposes are mentioned below</p>

          <ul className='list'>
            <li>The information is used in order to handle the orders, deliver services and products, process payments and for promotional offers.</li>
            <li>It is also used for the purpose of functionality, analysing performance, rectifying errors and improving the usability of our services.</li>
            <li>With the help of the information provided by you, we become able to recommend features, products and services of your interest.</li>
            <li>It also helps us to comply with all the applicable laws.</li>
            <li>With the help of this, we can communicate with you regarding our products and services.</li>
          </ul>

          <p>
            <i>All in all, your personal details are only used for the purpose of offering products and services while complying with laws.</i>
          </p>

          <h2>5. How We Share Your Information</h2>
          <p>Your information can be shared internally and externally with our partners for business purposes. However, proper checks are being implemented in order to confirm that your information can be shared according to the applicable laws. We may share your information with</p>

          <ul className='list'>
            <li>Our authorised partners.</li>
            <li>With the service providers.</li>
            <li>With other entities but with your consent.</li>
            <li>For responding to legal requests.</li>
          </ul>

        </div>
      </section>







    </Layout>
  )
}


export default PrivacyPolicy