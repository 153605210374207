import React from 'react';

const Heading = ({ title = "", text = "" }) => {
    return (
        <div className='md:mb-[60px] mb-[50px] text-center relative after:content-[""] after:absolute md:after:w-[150px] after:w-[120px] after:left-0 after:right-0 after:bottom-[-10px] after:m-auto md:after:h-[5px] after:h-[3px] after:bg-[linear-gradient(-90deg,#D400FE_0%,#1036DE_100%)]'>
            <h2>{title}</h2>

            {text && (
                <p className='-mt-[10px] pb-[20px]'>{text}</p>
            )}
        </div>
    )
}

export default Heading;