import React from 'react';
import { Link } from 'react-router-dom';

const InnerPageBanner = ({ title = "", innerPageBanner = "" }) => {
    return (
        <section className='relative md:py-[170px] lg:mt-[107px] mt-[68px] max-md:py-[80px] bg-center bg-cover bg-no-repeat after:content-[""] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[linear-gradient(0deg,#D400FE_0%,#1036DE_100%)] after:opacity-80' style={{ backgroundImage: `url(${innerPageBanner})` }}>
            <div className='heading_tittle container relative z-10'>
                {title && (
                    <h1 className="capitalize text-white text-center tracking-[2px] drop-shadow-[0_0_5px_#00000052] mb-0">{title}</h1>
                )}
                <div className='text-center mt-[15px]'>
                    <ul>
                        <li className='inline-block'>
                            <Link className='text-white text-[18px]' to='/'>Home</Link>
                        </li>
                        <span className='px-[10px] text-white text-[18px]'>/</span>
                        <li className='inline-block text-white text-[18px]'>{title}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default InnerPageBanner;