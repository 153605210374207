import React from "react";
import CountUp from 'react-countup';
import { GrTechnology } from "react-icons/gr";
import { RiGlobalFill } from "react-icons/ri";
import { GoBroadcast } from "react-icons/go";
import { TbDeviceMobileDollar } from "react-icons/tb";

const Counting = () => {
    return (
        <>
            <section className="pt-0">
                <div className="container m-auto">
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 justify-between items-center">
                        <div className='text-center group border-r max-md:border-b max-md:py-[25px] px-[15px] border-[#b4bbff]'>
                            <div className="mb-[10px]"> 
                                <span className="w-[100px] h-[100px] border-[5px] border-[#e5e8ff] bg-[#123a6d] group-hover:bg-[#1036de] duration-500 inline-flex items-center justify-center rounded-full"><RiGlobalFill className="text-[55px] text-[#fff]" /></span>
                            </div>
                            <CountUp start={0} end={200} duration={1}>
                                {({ countUpRef, start }) => (
                                    <span className="xl:text-[48px] lg:text-[44px] text-[#123a6d] sm:text-[36px] text-[25px] font-bold inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-bold after:relative" ref={countUpRef}></span>
                                )}
                            </CountUp>
                            <p className="max-sm:min-h-[42px] mb-0 xl:text-[18px] font-bold sm:text-[16px] text-[14px]">Global Carriers</p>
                        </div>

                        <div className='text-center group border-r max-md:border-0 max-md:border-b max-md:py-[25px] px-[15px] border-[#b4bbff]'>
                            <div className="mb-[10px]"> 
                                <span className="w-[100px] h-[100px] border-[5px] border-[#e5e8ff] bg-[#123a6d] group-hover:bg-[#1036de] duration-500 inline-flex items-center justify-center rounded-full"><GrTechnology className="text-[55px] text-[#fff]" /></span>
                            </div>
                            <CountUp start={0} end={150} duration={2}>
                                {({ countUpRef, start }) => (
                                    <span className="xl:text-[48px] lg:text-[44px] text-[#123a6d] sm:text-[36px] text-[25px] font-bold inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-bold after:relative" ref={countUpRef}></span>
                                )}
                            </CountUp>
                            <p className="max-sm:min-h-[42px] mb-0 xl:text-[18px] font-bold sm:text-[16px] text-[14px]">Global Technology Deployments</p>
                        </div>

                        <div className='text-center group border-r max-md:py-[25px] px-[15px] border-[#b4bbff]'>
                            <div className="mb-[10px]"> 
                                <span className="w-[100px] h-[100px] border-[5px] border-[#e5e8ff] bg-[#123a6d] group-hover:bg-[#1036de] duration-500 inline-flex items-center justify-center rounded-full"><TbDeviceMobileDollar className="text-[55px] text-[#fff]" /></span>
                            </div>
                            <CountUp start={0} end={45} duration={2}>
                                {({ countUpRef, start }) => (
                                    <span className="xl:text-[48px] lg:text-[44px] text-[#123a6d] sm:text-[36px] text-[25px] font-bold inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-bold after:relative" ref={countUpRef}></span>
                                )}
                            </CountUp>
                            <p className="max-sm:min-h-[42px] mb-0 xl:text-[18px] font-bold sm:text-[16px] text-[14px]">Mobile Money Wallets</p>
                        </div>

                        <div className='text-center group max-md:py-[25px]'>
                            <div className="mb-[10px]"> 
                                <span className="w-[100px] h-[100px] border-[5px] border-[#e5e8ff] bg-[#123a6d] group-hover:bg-[#1036de] duration-500 inline-flex items-center justify-center rounded-full"><GoBroadcast className="text-[55px] text-[#fff]" /></span>
                            </div>
                            <CountUp start={0} end={180} duration={2}>
                                {({ countUpRef, start }) => (
                                    <span className="xl:text-[48px] lg:text-[44px] text-[#123a6d] sm:text-[36px] text-[25px] font-bold inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-bold after:relative" ref={countUpRef}></span>
                                )}
                            </CountUp>
                            <p className="max-sm:min-h-[42px] mb-0 xl:text-[18px] font-bold sm:text-[16px] text-[14px]">Tier 1 Directs</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Counting