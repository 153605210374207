import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IoMdMenu } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';

const DrawerMenu = ({ navLinks }) => {
  const navigate = useNavigate();
  const [toggleDropdown, setToggleDropdown] = React.useState(false);
  const pathname = useLocation().pathname;

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
        <List className='!py-0'>
          <div className='px-[16px] bg-[#fff] border-b border-[#eaedf1]'>
            <Link to="/" >
              <img className='h-[73px]' src={ImageIcons.logo} alt='' />
            </Link>
          </div>
          {navLinks.map((item, index) => (
            <div>
              <ListItem key={index} disablePadding className={`border-solid border-b border-[#eaedf1] `}>
                <ListItemButton onClick={() => navigate(item.handle)}>
                  <ListItemText className={` ${pathname === item.handle && "!text-[#d200fe]"}`} primary={item.title} />
                </ListItemButton>
                {item.subLinks && (
                  <ListItemButton className='!flex !justify-end !absolute right-0 text-[20px] h-full !bg-[#123a6d] !text-white' onClick={() => setToggleDropdown(prev => !prev)}>{toggleDropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}</ListItemButton>
                )}
              </ListItem>
              {item.subLinks && (
                <Collapse in={toggleDropdown} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subLinks.map((item, i) => (
                      <ListItem key={i} disablePadding className={`bg-[#f7f8ff] border-solid border-b border-[#eaedf1]`}>
                        <ListItemButton
                          onClick={() => {
                            navigate(`${item.handle}`)
                            toggleDrawer('left', false);
                          }}
                        >
                          <ListItemText className={` ${pathname === item.handle && "!text-[#d200fe]"} pl-[15px] `} primary={item.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
        <List>
        </List>
      </Box>
    </Box>
  );
  return (
    <>
      <div>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)} className='!text-current'><IoMdMenu className='text-[36px]' /></Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default DrawerMenu