import React from 'react';
import Layout from '../components/shared/layout';
import Hero from '../components/home/hero';
import About from '../components/home/about';
import Counting from '../components/home/counting';
import Team from '../components/home/team';
import Businesses from '../components/home/businesses';
import Innovation from '../components/home/innovation';
import BrandPortfolio from '../components/home/brandPortfolio';
import DistinguishedFeatures from '../components/home/DistinguishedFeatures';
import CoreValues from '../components/home/CoreValues';
import Blogs from '../components/home/blogs';
import Testimonial from '../components/home/testimonials';
 
const Home = () => {
  return (
    <Layout menuColor="[#fff]">
      <Hero />
      <About />
      <Counting />
      <Team />
      <Businesses />
      <Innovation />
      <BrandPortfolio />
      <CoreValues />
      <DistinguishedFeatures />
      <Testimonial />
      <Blogs />
    </Layout>
  )
}

export default Home