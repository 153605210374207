import React from 'react'
import Heading from '../shared/heading';
import ImageIcons from '../imageComponent/ImageIcons';
// import { RiGlobalFill } from "react-icons/ri";

const DistinguishedFeatures = () => {
    return (
        <>
            <section className='dis-features-section bg-auto bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.featurebgimg}), url(${ImageIcons.feashape})` }}>
                <div className='container m-auto'>
                    <Heading title="Distinguished Features" />
                </div>
                <div className='container m-auto'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 justify-between items-center max-lg:gap-y-[85px] gap-x-[24px] mt-[70px] pt-[70px]'>
                        <div className='group h-full text-center w-full relative shadow-[0px_0px_20px_0px_rgba(106,121,255,0.37)] bg-[#fff] p-[20px] rounded-[10px] hover:bg-[#1036DE] cursor-pointer duration-500'>
                            <div className="mt-[-79px] mb-[30px]">
                                <span className="w-[180px] h-[120px] bg-[#fff] border-[2px] border-[#123A6D]/[50%] inline-flex items-center justify-center rounded-[10px] group-hover:border-[#1036DE] duration-500">
                                    {/* <RiGlobalFill className="text-[55px] text-[#123A6D] group-hover:text-[#1036DE] duration-500" /> */}
                                    <img className='w-[75px] h-[75px]' src={ImageIcons.featureicon1} alt='' />
                                </span>
                            </div>
                            <h4 className='text-[23px] leading-[32px] font-bold mb-3 group-hover:text-[#fff] duration-500'>Integrated Ecosystem</h4>
                            <p className='text-[16px] leading-[21px] group-hover:text-[#fff] duration-500'>Your life becomes easy with our comprehensive range of services, working together seamlessly, so there is no need of handling multiple providers.</p>
                        </div>
                        <div className='group h-full text-center w-full relative shadow-[0px_0px_20px_0px_rgba(106,121,255,0.37)] bg-[#fff] p-[20px] rounded-[10px] hover:bg-[#1036DE] cursor-pointer duration-500'>
                            <div className="mt-[-79px] mb-[30px]">
                                <span className="w-[180px] h-[120px] bg-[#fff] border-[2px] border-[#123A6D]/[50%] inline-flex items-center justify-center rounded-[10px] group-hover:border-[#1036DE] duration-500">
                                    {/* <RiGlobalFill className="text-[55px] text-[#123A6D] group-hover:text-[#1036DE] duration-500" /> */}
                                    <img className='w-[75px] h-[75px]' src={ImageIcons.featureicon2} alt='' />
                                </span>
                            </div>
                            <h4 className='text-[23px] leading-[32px] font-bold mb-3 group-hover:text-[#fff] duration-500'>Cutting-Edge Technology</h4>
                            <p className='text-[16px] leading-[21px] group-hover:text-[#fff] duration-500'>We are committed to explore new possibilities and innovations so that you can be served in a better way, assisting you stay abreast.</p>
                        </div>
                        <div className='group h-full text-center w-full relative shadow-[0px_0px_20px_0px_rgba(106,121,255,0.37)] bg-[#fff] p-[20px] rounded-[10px] hover:bg-[#1036DE] cursor-pointer duration-500'>
                            <div className="mt-[-79px] mb-[30px]">
                                <span className="w-[180px] h-[120px] bg-[#fff] border-[2px] border-[#123A6D]/[50%] inline-flex items-center justify-center rounded-[10px] group-hover:border-[#1036DE] duration-500">
                                    {/* <RiGlobalFill className="text-[55px] text-[#123A6D] group-hover:text-[#1036DE] duration-500" /> */}
                                    <img className='w-[75px] h-[75px]' src={ImageIcons.featureicon3} alt='' />
                                </span>
                            </div>
                            <h4 className='text-[23px] leading-[32px] font-bold mb-3 group-hover:text-[#fff] duration-500'>Sustainability Leadership</h4>
                            <p className='text-[16px] leading-[21px] group-hover:text-[#fff] duration-500'>Our commitment to protect the environment inspires us to set the standards for following responsible business practices.</p>
                        </div>
                        <div className='group h-full text-center w-full relative shadow-[0px_0px_20px_0px_rgba(106,121,255,0.37)] bg-[#fff] p-[20px] rounded-[10px] hover:bg-[#1036DE] cursor-pointer duration-500'>
                            <div className="mt-[-79px] mb-[30px]">
                                <span className="w-[180px] h-[120px] bg-[#fff] border-[2px] border-[#123A6D]/[50%] inline-flex items-center justify-center rounded-[10px] group-hover:border-[#1036DE] duration-500">
                                    {/* <RiGlobalFill className="text-[55px] text-[#123A6D] group-hover:text-[#1036DE] duration-500" /> */}
                                    <img className='w-[75px] h-[75px]' src={ImageIcons.featureicon4} alt='' />
                                </span>
                            </div>
                            <h4 className='text-[23px] leading-[32px] font-bold mb-3 group-hover:text-[#fff] duration-500'>Reliability and Security</h4>
                            <p className='text-[16px] leading-[21px] group-hover:text-[#fff] duration-500'>Our services are completely reliable and secure. We guarantee keeping your data safe and protected along with ensuring smooth operation of the system.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DistinguishedFeatures
