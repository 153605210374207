import React, { useEffect, useState } from 'react';
import ImageIcons from '../imageComponent/ImageIcons';
import Heading from '../shared/heading';
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';

const BrandPortfolio = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isOpen);

    return () => {
      document.body.classList.remove('overflow-visible');
    };
  }, [isOpen]);

  const togglePopup = (index) => {
    setIsOpen(index === isOpen ? null : index);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <section>
      <div className='container'>
        <Heading title="Our Companies" />

        <div className=' bg-center bg-cover bg-no-repeat flex flex-wrap 2xl:gap-[30px_200px] xl:gap-[30px_140px] lg:gap-[15px_45px] gap-[20px_20px] justify-center' style={{ backgroundImage: `url(${ImageIcons.brandLogoBg})` }}>
          {brandData.map((item, i) => (
            <div key={i} onClick={() => togglePopup(i)} className='w-[200px] h-[200px] max-lg:w-[150px] max-lg:h-[150px] max-md:w-[130px] max-md:h-[130px] rounded-full p-[45px] max-lg:p-[35px] max-md:p-[30px] shadow-[0px_5px_15px_0px_rgba(106,121,255,0.37)] bg-white [&:nth-child(6)]:mx-[50px] [&:nth-child(6)]:max-md:mt-0 [&:nth-child(6)]:max-md:mx-0 [&:nth-child(2)]:mt-[30px] [&:nth-child(2)]:max-md:mt-0 [&:nth-child(3)]:mt-[60px] [&:nth-child(3)]:max-md:mt-0 [&:nth-child(6)]:mt-[40px] [&:nth-child(9)]:mt-[40px] [&:nth-child(9)]:max-md:mt-0 cursor-pointer duration-1000 hover:shadow-[0px_5px_100px_0px_rgba(106,121,255,0.37)] group'>
              <img className='w-full h-full object-contain object-center duration-1000 group-hover:scale-[1.1]' src={item.brandLogo} alt={item.brandLogoTitle} />

              {isOpen === i && (
                <div className="overflow-y-auto bg-[linear-gradient(0deg,rgb(212_0_254_/_60%)_0%,rgb(16_54_222_/_50%)_100%)] overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100vh] max-h-full flex items-center justify-center duration-500 cursor-auto px-[15px]" onClick={handleClose}>
                  {item.brandWebData && item.brandWebData.map((item, i) => (
                    <div key={i} className="max-w-[800px] bg-[#fff] p-[30px] rounded-[10px]" onClick={(e) => e.stopPropagation()}>
                      <div className='flex items-center justify-between mb-[25px] duration-500 border-b border-[#2a2fe229] pb-[15px]'>
                        <h2 className='text-[32px] max-md:text-[28px] mb-0'>{item.title}</h2>
                        <IoMdClose onClick={handleClose} className='cursor-pointer text-[32px] max-md:text-[28px] hover:text-[#6A79FF] duration-500 rounded-full' />
                      </div>
                      <p className='text-[18px] mb-[25px]'>{item.text}</p>
                      <Link to={item.link} target="_blank" className="px-[40px] text-[18px] py-[15px] font-medium text-[#fff] bg-[linear-gradient(90deg,#D400FE_0%,#1036DE_100%)] rounded-[10px] hover:text-[#fff] hover:bg-[#1036de] ease inline-block">
                        Live Website
                        <span className="animate-pulse ml-[10px] relative inline-flex rounded-full h-[12px] w-[12px] bg-[#1fff3c]"></span>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

    </section>
  )
}

const brandData = [
  {
    brandLogo: ImageIcons.myCloudCam,
    brandLogoTitle: 'My Cloud Cam Logo',
    brandWebData: [
      {
        title: "MyCloudCam",
        text: "myCloudCam is India’s first cloud based CCTV surveillance solutions offering company, where we allow the customer to have an access to the live camera view and recordings while sitting in any corner of the world at any time. The recordings are stored safely on the cloud, so that these can be accessed by you effortlessly. For our services",
        link: 'https://www.mycloudcam.in/',
      },
    ]
  },
  {
    brandLogo: ImageIcons.abacus,
    brandLogoTitle: 'Abacus Logo',
    brandWebData: [
      {
        title: "TheAbacus",
        text: "TheAbacus is a leading cloud storage solution provider in India and our services include Private Cloud Services, Public Cloud Services, Rackspace, Bare Metal Services, Interconnection, storage Solutions, Content Delivery, VPS hosting Solutions and Managed Network Services. Join us today and empower your digital journey to take your business to new heights. For more information",
        link: 'https://www.theabacus.in',
      },
    ]
  },
  {
    brandLogo: ImageIcons.myInternet,
    brandLogoTitle: 'My Internet Logo',
    brandWebData: [
      {
        title: "MyInternet",
        text: "Considering the internet needs of today’s generation, we started myInternet services to provide reliable, secure and high-speed internet connection. We offer broadband services, WiFi services, 4G , 5G and much more. To take our subscription",
        link: 'https://www.myinternet.services',
      },
    ]
  },
  {
    brandLogo: ImageIcons.ashke,
    brandLogoTitle: 'Ashke TV',
    brandWebData: [
      {
        title: "Ashke TV",
        text: "Ashke TV is our OTT services providing company, which provides a broad array of content and programming such as 300+ live TV channels, movies, short plays and sports channels. Our services serve the varying needs of customers so that they can have a unique and engaging streaming experience for users all over India. Experience a new world of entertainment with Ashke TV",
        link: '#',
      },
    ]
  },
  {
    brandLogo: ImageIcons.vpBradband,
    brandLogoTitle: 'VP Bradband Logo',
    brandWebData: [
      {
        title: "VP Broadband",
        text: "VP Broadband is a pioneer in providing bulk bandwidth and MPLS services all over India. Our services have been designed to meet the unique requirements of both small and large enterprises. At VP Broadband, we offer robust, fast and secure internet solutions in order to support the growing demands of your business. Join us today and get top-notch internet solutions",
        link: '#',
      },
    ]
  },
  {
    brandLogo: ImageIcons.myEv,
    brandLogoTitle: 'My Ev Point Logo',
    brandWebData: [
      {
        title: "MyEVpoint",
        text: "As we are committed towards promoting a cleaner and greener tomorrow, we have come forward to promote electric vehicles with our initiative of EV charging stations in the name of myEVpoint. Our charging stations located at multiple popular spots ensure easy access to EV drivers along with encouraging others to adopt electric vehicles and abandon traditional one’s. For our services and purchasing our franchise",
        link: 'https://www.myevpoint.in/',
      },
    ]
  },
  {
    brandLogo: ImageIcons.skypro,
    brandLogoTitle: 'Skypro Logo',
    brandWebData: [
      {
        title: "Skypro",
        text: "Skypro is a premier IPTV services provider company, where we offer users with a wide range of channels and content, catering to different needs and preferences of the people. We have a community of thousands of satisfied customers. Come join us today and immerse yourself into the realm of entertainment. For any information regarding our plans and packages",
        link: 'https://skypro.co.in',
      },
    ]
  },
  {
    brandLogo: ImageIcons.myJet,
    brandLogoTitle: 'My Jet Logo',
    brandWebData: [
      {
        title: "MyJet",
        text: "MyJet is the service of providing private chartered flights, where we offer users impeccable and luxurious services. We ensure exclusive experience of flying and whole time unmatchable support for those who seek comfort and convenience.  So, wait for what? Come and experience the epitome of luxury with My Jet private chartered flights provider. For more information",
        link: '#',
      },
    ]
  },
  {
    brandLogo: ImageIcons.immigration,
    brandLogoTitle: 'Immigration Logo',
    brandWebData: [
      {
        title: "Immigration",
        text: "Get all the information about a broad array of immigration and visa counselling services with our punjabi web channel, Immigration Timez. We ensure providing latest updates, guidelines and expert guidance so that you can be assisted while facing the difficulties of the process of immigration. To stay informed",
        link: '#',
      },
    ]
  },
  {
    brandLogo: ImageIcons.myAir,
    brandLogoTitle: 'My Air Logo',
    brandWebData: [
      {
        title: "MyAirAmbulance",
        text: "Experience best and impeccable chartered and quick air ambulance services with MyAirAmbulance, which offer you on-board ICU facilities, providing a broad assortment of medical care during the times of emergency. Our quick and efficient services offer essential medical support when it is required the most. For more information",
        link: '#',
      },
    ]
  },
  {
    brandLogo: ImageIcons.myWifi,
    brandLogoTitle: 'My Wifi Logo',
    brandWebData: [
      {
        title: "MyWifi",
        text: "MyWiFi provides its users with a seamless and reliable corporate and free WiFi services, which are customised to the unique requirements of the businesses and public spaces. With our services, you will always stay connected to high-speed internet in every corner. Join us and get experience of the future of connectivity. To subscribe to our services",
        link: '#',
      },
    ]
  },

];

export default BrandPortfolio