import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';


const CancellationsAndRefunds = () => {
  return (
    <Layout>
      <InnerPageBanner title="Refund Policy" innerPageBanner={ImageIcons.contactBg} />

      <section>
        <div className='container'>
          <p>VP Group is committed to offer customers with outstanding services and high-quality products.  As we completely understand that sometimes there is a need to request a refund, we have designed a transparent refund policy so that customer satisfaction can be ensured.</p>

          <h2>Guidelines</h2>

          <h3>1. Eligibility</h3>
          <ul className='list'>
            <li>It is important to understand that refunds are not available for all the products, refunds can only be availed for some of the services and products only.</li>
            <li>In order to be eligible for a refund, you need to provide proof of your purchase along with meeting the conditions which are being outlined for each of the services.</li>
          </ul>

          <h3>2. Timeframe for Refund Requests</h3>
          <ul className='list'>
            <li>It needs to be understood that a refund request has to be submitted within 30 days of the purchase of your services. If the request is being made after the time period of 30 days, you will not be considered eligible for a refund.</li>
          </ul>

          <h3>3. Method of Refund</h3>
          <ul className='list'>
            <li>Refunds will always be provided via that payment method which has been used by you.</li>
            <li>It will take a time period of 5-10 business days in order to process the refunds and to reflect in your account.</li>
          </ul>

          <h3>4. The Procedure of Requesting a Refund</h3>
          <p>If you want to request a refund, you can contact our customer support team but you need to provide us with all the information.</p>
          <ul className='list'>
            <li>Your full name</li>
            <li>All the details of your contact like email, phone number etc.</li>
            <li>The proof of your purchase such as receipt or order number.</li>
            <li>You also need to mention the reason for the refund request you made.</li>
          </ul>

          <p>
            <i>Your refund request will be reviewed by our customer support team and we ensure responding within 5-10 business days. In case, your refund request gets approved, your refund will be initiated and you will also receive a confirmation mail regarding the same.</i>
          </p>
          <p>
            <i>VP Group has all the rights to make changes in our refund policy at any time and we encourage you to read our refund policy from time to time. The changes will immediately be posted on the website of the VP Group.
            </i>
          </p>
          <p>
            <i>We are grateful to you for choosing VP Group. </i>
          </p>

        </div>
      </section>







    </Layout>
  )
}


export default CancellationsAndRefunds