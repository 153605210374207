import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/home';
import ContactUs from './pages/contactUs';
import AboutUs from './pages/aboutUs';
import NotFoundPage from './pages/404';
import Products from './pages/products';
import Blogs from './pages/blogs';
import BlogDetails from './pages/blogDetails';
import Sitemap from './pages/sitemap';
import TermsAndConditions from './pages/termsAndConditions';
import PrivacyPolicy from './pages/privacyPolicy';
import CancellationsAndRefunds from './pages/cancellationsAndRefunds';
import Thanks from './pages/thanks';
import Accreditations from './pages/accreditations';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/blogs" exact element={<Blogs />} />
        <Route path="/blog-details" exact element={<BlogDetails />} />
        <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/cancellations-and-refunds" exact element={<CancellationsAndRefunds />} />
        <Route path="/sitemap" exact element={<Sitemap />} />
        <Route path="/accreditations" exact element={<Accreditations />} />
        <Route path="/thank-you" exact element={<Thanks />} />
        <Route path="*" exact element={<NotFoundPage />} />

      </Routes>

    </BrowserRouter>
  );
}

export default App;
